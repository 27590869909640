import {createSlice} from "@reduxjs/toolkit"

const initialState = []

const templatesCategoriesSlice = createSlice({
    name: "templatesCategories",
    initialState,
    reducers: {
        templatesCategoriesList(state, action) {
            if (Array.isArray(action.payload)){
                return action.payload
            } else {
                console.error('Payload is not an array: ', action.payload)
            }
        }
    }
})

export const {templatesCategoriesList} = templatesCategoriesSlice.actions
export default templatesCategoriesSlice.reducer