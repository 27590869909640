import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const fontFileSlice = createSlice({
  name: "fontFile",
  initialState,
  reducers: {
    fontFileList(state, action) {
        state.push(action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { fontFileList } = fontFileSlice.actions;

export default fontFileSlice.reducer;
