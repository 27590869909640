import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const fontsSlice = createSlice({
  name: "fonts",
  initialState,
  reducers: {
    fontsList(state, action) {
        state.push(...action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { fontsList } = fontsSlice.actions;

export default fontsSlice.reducer;
