/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const EyeLine1 = ({ color = "#008A00", className }) => {
  return (
    <svg
      className={`eye-line-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.8026 10.1104L17.8276 10L17.8026 9.88959C16.9869 6.28676 13.7654 3.66667 10.0004 3.66667C6.23538 3.66667 3.0139 6.28676 2.19816 9.88959L2.17316 10L2.19816 10.1104C3.0139 13.7133 6.23538 16.3333 10.0004 16.3333C13.7654 16.3333 16.9869 13.7133 17.8026 10.1104ZM10.0004 3C14.2164 3 17.7303 6.01097 18.507 10C17.7303 13.989 14.2164 17 10.0004 17C5.78433 17 2.2705 13.989 1.49373 10C2.2705 6.01097 5.78433 3 10.0004 3ZM10.0004 13.25C8.20545 13.25 6.75038 11.7949 6.75038 10C6.75038 8.20508 8.20545 6.75 10.0004 6.75C11.7953 6.75 13.2504 8.20508 13.2504 10C13.2504 11.7949 11.7953 13.25 10.0004 13.25ZM10.0004 12.5833C11.4271 12.5833 12.5837 11.4267 12.5837 10C12.5837 8.57327 11.4271 7.41667 10.0004 7.41667C8.5737 7.41667 7.41705 8.57326 7.41705 10C7.41705 11.4267 8.5737 12.5833 10.0004 12.5833Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

EyeLine1.propTypes = {
  color: PropTypes.string,
};
