/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const KeyboardLine = ({ className }) => {
  return (
    <svg
      className={`keyboard-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3 14.667H17V15.3337H3V14.667ZM3 9.66699H4.5V11.167H3V9.66699ZM7.16667 9.66699H8.66667V11.167H7.16667V9.66699ZM3 4.66699H4.5V6.16699H3V4.66699ZM11.3333 4.66699H12.8333V6.16699H11.3333V4.66699ZM15.5 4.66699H17V6.16699H15.5V4.66699ZM11.3333 9.66699H12.8333V11.167H11.3333V9.66699ZM15.5 9.66699H17V11.167H15.5V9.66699ZM7.16667 4.66699H8.66667V6.16699H7.16667V4.66699Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
    </svg>
  );
};
