/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArrowRightSLine = ({ className }) => {
  return (
    <svg
      className={`arrow-right-s-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.8296 10.3541L12.1832 10.0006L11.8296 9.64703L8.05842 5.87578L8.52983 5.40437L13.1261 10.0006L8.52983 14.5967L8.05843 14.1253L11.8296 10.3541Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
    </svg>
  );
};
