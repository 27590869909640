import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    stateDetail(state, action) {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { stateDetail } = stateSlice.actions;

export default stateSlice.reducer;
