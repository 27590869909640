import React, { useState, useRef, useMemo, useCallback } from "react";
import axios from "axios";
import { Divider } from "../../icons/Divider";
import { ArrowLeftSLine } from "../../components/ArrowLeftSLine";
import { ShoppingCartLine } from "../../icons/ShoppingCartLine";
import { ArrowDownSLine6 } from "../../icons/ArrowDownSLine6";
import { SaveLineWrapper } from "../../components/SaveLineWrapper";
import { Avatar } from "../../components/Avatar";
import { ShareLineWrapper } from "../../components/ShareLineWrapper";
import { RiCloseCircleLine4Wrapper } from "../../components/RiCloseCircleLine4Wrapper";
import { InputCheckbox } from "../../components/InputCheckbox";
import { ArrowRightSLine } from "../../icons/ArrowRightSLine";
import { EyeLine2 } from "../../icons/EyeLine2";
import { RiCloseCircleLine } from "../../icons/RiCloseCircleLine";
import "./Header.css";

export const Header = ({
  canvas,
  saveDesign,
  setDesign,
  setAlert,
  setTitle,
  setErrorMessage,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [save, setSave] = useState(false);
  const [signin, setSignin] = useState(false);
  const [designName, setDesignName] = useState(false);
  const [designSaved, setDesignSaved] = useState(false);
  const [loader, setLoader] = useState(false);
  const design_name = useRef(null);
  const apiBaseUrl = localStorage.getItem("apiBaseUrl");
  const token = localStorage.getItem("user-auth-token");

  const requestOptions = useMemo(() => {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    };
  }, [token]);

  const saveCurrentDesign = useCallback(
    (payload) => {
      axios
        .post(apiBaseUrl + `save-design`, payload, requestOptions, {
          signal: AbortSignal.timeout(30000),
        })
        .then(
          (response) => {
            if (
              response &&
              response?.data &&
              response?.data?.data &&
              response?.data?.data?.status &&
              response?.data?.data?.status === 200
            ) {
              setLoader(false);
              setDesignSaved(true);
              setDesign(true);
            }
          },
          (error) => {
            setLoader(false);
            setAlert(true);
            setTitle("Save Design");
            setErrorMessage(error?.response?.data?.message);
          }
        );
    },
    [requestOptions, apiBaseUrl, setDesign, setAlert, setTitle, setErrorMessage]
  );

  return (
    <>
      <div className="header-container">
        <div className="pre-header-wrapper-4">
          <div className="header-left-wrapper">
            <ArrowLeftSLine
              className="arrow-left-s-line-instance"
              color="#6E7191"
            />
            <img
              className="rectangle-49"
              alt="Rectangle"
              src="/img/rectangle-1.png"
            />
            <Divider className="divider-14" />
            <button className="btn-17 cursor-pointer">
              <div className="left-div-7">
                <Avatar
                  border={false}
                  className="avatar-6"
                  overlapGroupClassName="avatar-7"
                  person="default"
                  rectangle="/img/rectangle-457.png"
                  status={false}
                  story={false}
                  type="photo"
                />
                <div className="text-wrapper-58">Login to save progress</div>
              </div>
            </button>
          </div>
          <div className="header-right-wrapper">
            <div className="btn-19">
              <ShareLineWrapper />
            </div>
            <button
              className="btn-16 cursor-pointer"
              onClick={() => /* setSave(true) */ setDesignName(true)}
            >
              <div className="left-div-7">
                <SaveLineWrapper />
                <div className="text-wrapper-58">Save</div>
              </div>
            </button>
            {/* <button
              className="btn-18 cursor-pointer"
              onClick={() => setShowPreview(true)}
            >
              <div className="left-div-7">
                <Box3Line className="icon-instance-node-7" color="#FCFCFC" />
                <div className="text-wrapper-57">3D Preview</div>
              </div>
            </button> */}
            <button
              className="btn-15 cursor-pointer"
              onClick={() => setShowCart(true)}
            >
              <div className="left-div-7">
                <ShoppingCartLine className="icon-instance-node-7" />
                <div className="text-wrapper-57">$187.60</div>
              </div>
              <ArrowDownSLine6
                className="icon-instance-node-7"
                color="#FCFCFC"
              />
            </button>
            {showCart === true && (
              <>
                <div className="cart-wrapper">
                  <div className="overlap">
                    <div className="text-wrapper-10">ITEM #1</div>
                    <span
                      className="ri-close-circle-line-1 cursor-pointer"
                      onClick={() => setShowCart(false)}
                    >
                      <RiCloseCircleLine className="abc" color="#fcfcfc" />
                    </span>
                    <img
                      className="container-3"
                      alt="Container"
                      src="/img/container-1.png"
                    />
                    <p className="text-wrapper-8">
                      Hanes Authentic T-shirt for Men
                    </p>
                    <div className="text-wrapper-11">SKU: SW-2002</div>
                    <div className="text-wrapper-12">Total Qty: 36</div>
                    <div className="text-wrapper-9">$187.60</div>
                    <div className="item-8">
                      <EyeLine2
                        className="icon-instance-node"
                        color="#FCFCFC"
                      />
                      <div className="text-wrapper-13">View Cost Breakdown</div>
                    </div>
                    <div className="divider-instance" />
                    <img
                      className="container-2"
                      alt="Container"
                      src="/img/container-2.svg"
                    />
                    <div className="check">
                      <p className="p">
                        By continuing, I verify that I am satisfied with the
                        design layout.
                      </p>
                      <InputCheckbox
                        className="input-checkbox"
                        darkmode={false}
                        indeterminate={false}
                        selected
                        size="small"
                        state="hover"
                        visible={false}
                      />
                    </div>
                    <div className="divider-1-instance" />
                    <div className="btn-4 cursor-pointer">
                      <div className="left-div-2">
                        <p className="text-wrapper-6">
                          Add to Cart &amp; Proceed
                        </p>
                        <ArrowRightSLine className="icon-instance-node" />
                      </div>
                    </div>
                    <div className="close">
                      <div className="overlap-4">
                        <div className="text-wrapper-22">Product Total</div>
                        <div className="text-wrapper-23">$187.50</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showPreview === true && (
        <>
          <div className="preview-wrapper">
            <div className="overlay" />
            <div className="group-7">
              <div className="overlap-17">
                <img
                  className="divider-16"
                  alt="Divider"
                  src="/img/divider-7.svg"
                />
                <div className="text-wrapper-61">3D Preview</div>
                <div className="close-icon">
                  <span
                    className="cursor-pointer"
                    onClick={() => setShowPreview(false)}
                  >
                    <RiCloseCircleLine4Wrapper />
                  </span>
                </div>
                <div className="group-8">
                  <div className="container-9" />
                  <div className="group-9">
                    <img
                      className="rotate-right cursor-pointer"
                      alt="Rotate right"
                      src="/img/rotate-right-3.svg"
                    />
                    <img
                      className="rotate-left cursor-pointer"
                      alt="Rotate left"
                      src="/img/rotate-left.svg"
                    />
                  </div>
                  <p className="text-wrapper-62">
                    Drag with your mouse or icons to rotate
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {save === true && (
        <>
          <div className="save-wrapper">
            <div className="overlap-63">
              <div className="overlay" />
              <div className="group-19">
                <div className="sign-in-wrapper">
                  <div className="overlap-group-22">
                    <img
                      className="ri-close-circle-line-4 cursor-pointer"
                      alt="Ri close circle line"
                      src="/img/ri-close-circle-line-3.svg"
                      onClick={() => setSave(false)}
                    />
                    <p className="seems-like-you-re-on">
                      Seems like you&#39;re on your way out, but the design
                      progress is still in full swing!
                    </p>
                    <p className="text-wrapper-148">
                      Do you want to save your progress before leaving?
                    </p>
                    <img
                      className="divider-44"
                      alt="Divider"
                      src="/img/divider-7-2.svg"
                    />
                    <div className="group-20">
                      <div
                        className="button-primary cursor-pointer"
                        onClick={() => {
                          setSave(false);
                          if (
                            localStorage.getItem("user-login") === undefined ||
                            localStorage.getItem("user-login") === false
                          ) {
                            setSignin(true);
                          } else {
                            setDesignName(true);
                          }
                        }}
                      >
                        <button className="button-2">Yes</button>
                      </div>
                      <div
                        className="button-wrapper cursor-pointer"
                        onClick={() => setSave(false)}
                      >
                        <button className="button-3">No</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {signin === true && (
        <>
          <div className="signin-wrapper">
            <div className="overlap-68">
              <div className="overlay-2" />
              <div className="group-23">
                <div className="sign-in-wrapper-2">
                  <div className="overlap-group-26">
                    <img
                      className="ri-close-circle-line-5 cursor-pointer"
                      alt="Ri close circle line"
                      src="/img/ri-close-circle-line-3.svg"
                      onClick={() => setSignin(false)}
                    />
                    <img
                      className="divider-51"
                      alt="Divider"
                      src="/img/divider-7.svg"
                    />
                    <div className="text-input">
                      <div className="frame-3">
                        <p className="input-label">
                          <span className="span">Email address </span>
                          <span className="text-wrapper-167">*</span>
                        </p>
                        <input
                          className="example"
                          type="text"
                          defaultValue={"amit@swagflo.com"}
                        />
                      </div>
                      <div className="close-10" />
                    </div>
                    <div className="text-input-2">
                      <div className="frame-3">
                        <p className="input-label">
                          <span className="span">Password </span>
                          <span className="text-wrapper-167">*</span>
                        </p>
                        <input
                          className="example"
                          type="password"
                          defaultValue={"***********"}
                        />
                      </div>
                      <img
                        className="icon-instance-node-17 cursor-pointer"
                        alt="Close"
                        src="/img/close.svg"
                      />
                    </div>
                    <img
                      className="divider-52"
                      alt="Divider"
                      src="/img/divider-8.svg"
                    />
                    <button className="button-ghost cursor-pointer">
                      <span className="button-4">Forgot Password?</span>
                    </button>
                    <div
                      className="button-primary-2 cursor-pointer"
                      onClick={() => {
                        setSignin(false);
                        setDesignName(true);
                      }}
                    >
                      <button className="button-5">Sign In</button>
                    </div>
                    <div className="button-primary-3">
                      <p className="button-6">
                        <span>New Customer ?</span>
                        <span className="cursor-pointer">Create Account</span>
                      </p>
                    </div>
                    <p className="text-wrapper-168">
                      Please sign In to save your progress.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {designName === true && (
        <>
          <div className="designname-wrapper">
            <div className="overlap-73">
              <div className="overlay-3" />
              <div className="group-26">
                <div className="sign-in-wrapper-3">
                  <div className="overlap-group-30">
                    <img
                      className="ri-close-circle-line-6 cursor-pointer"
                      alt="Ri close circle line"
                      src="/img/ri-close-circle-line-3.svg"
                      onClick={() => setDesignName(false)}
                    />
                    <p className="text-wrapper-189">
                      Welcome, {localStorage.getItem("user-name")}! Name your
                      awesome design.
                    </p>
                    <img
                      className="divider-59"
                      alt="Divider"
                      src="/img/divider-7.svg"
                    />
                    <div className="text-input-3">
                      <div className="frame-4">
                        <p className="input-label-2">
                          <span className="text-wrapper-187">Design Name </span>
                          <span className="text-wrapper-188">*</span>
                        </p>
                        <input
                          className="example-2"
                          ref={design_name}
                          type="text"
                          defaultValue={"Test"}
                        />
                      </div>
                      <div className="close-12" />
                    </div>
                    <img
                      className="divider-60"
                      alt="Divider"
                      src="/img/divider-8-2.svg"
                    />
                    <div
                      onClick={async () => {
                        if (design_name.current.value.length > 0) {
                          let designName = design_name.current.value;
                          setDesignName(false);
                          setLoader(true);
                          let design_data = [];
                          let design_payload = {};
                          await Promise.all(canvas).then(
                            async (canvas, index) => {
                              return await saveDesign(canvas, index).then(
                                (data) => {
                                  design_data.push(...data);
                                }
                              );
                            }
                          );
                          let page_label = [];
                          if (design_data[0]?.id === "back") {
                            design_data = design_data.reverse();
                          }
                          design_data.forEach((elem, index) => {
                            page_label.push(elem?.id);
                            design_payload[`text_svg_${index + 1}`] =
                              design_data[index]?.svg_data;
                            design_payload[`image_${index + 1}`] =
                              design_data[index]?.mockup_image;
                            design_payload[`print_area_image_${index + 1}`] =
                              design_data[index]?.image;
                            design_payload[`text_json_${index + 1}`] =
                              design_data[index]?.json_data;
                          });
                          design_payload.design_name = designName;
                          design_payload.product_id = 1;
                          design_payload.super_attributes = "";
                          design_payload.custom_options = "";
                          design_payload.custom_options_qty = "";
                          design_payload.qty = 1;
                          design_payload.zoom_factor = 1;
                          design_payload.number_of_pages = canvas.length;
                          design_payload.page_labels = page_label;
                          saveCurrentDesign(design_payload);
                        }
                      }}
                    >
                      <button className="button-primary-4 cursor-pointer">
                        <span className="button-7">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {designSaved === true && (
        <>
          <div className="designsaved-wrapper">
            <div className="overlap-78">
              <div className="overlay-4" />
              <div className="group-29">
                <div className="overlap-83">
                  <div className="sign-in-wrapper-4">
                    <div className="overlap-group-34">
                      <img
                        className="divider-67"
                        alt="Divider"
                        src="/img/image.svg"
                      />
                      <div
                        className="button-primary-5 cursor-pointer"
                        onClick={() => setDesignSaved(false)}
                      >
                        <button className="button-8">Keep Editing</button>
                      </div>
                      <p className="text-wrapper-208">
                        Your Design has been saved. You can find it under ‘Saved
                        Designs’ next time.
                      </p>
                      <img
                        className="ri-close-circle-line-7 cursor-pointer"
                        alt="Ri close circle line"
                        src="/img/ri-close-circle-line-3.svg"
                        onClick={() => setDesignSaved(false)}
                      />
                      <div
                        className="button-primary-6 cursor-pointer"
                        onClick={() => setDesignSaved(false)}
                      >
                        <button className="button-9">Exit Design Lab</button>
                      </div>
                    </div>
                  </div>
                  <img
                    className="rectangle-65"
                    alt="Rectangle"
                    src="/img/rectangle-463.gif"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {loader && (
        <div className="loader-wrapper">
          <div className="overlay-4" />
          <img className="loader" src="/img/loader.gif" alt="loader" />
        </div>
      )}
    </>
  );
};
