/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Scissors2Line = ({ className }) => {
  return (
    <svg
      className={`scissors-2-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g">
        <path
          className="path"
          d="M9.99984 11.1759L7.87165 13.3041C8.1649 13.8003 8.33317 14.3792 8.33317 14.9974C8.33317 16.8383 6.84079 18.3307 4.99984 18.3307C3.15889 18.3307 1.6665 16.8383 1.6665 14.9974C1.6665 13.1565 3.15889 11.6641 4.99984 11.6641C5.61801 11.6641 6.19688 11.8323 6.69315 12.1256L8.82134 9.99741L3.80398 4.98007C3.1531 4.3292 3.1531 3.27392 3.80398 2.62305L9.99984 8.81891L16.1957 2.62305C16.8466 3.27392 16.8466 4.3292 16.1957 4.98007L11.1783 9.99741L13.3065 12.1256C13.8028 11.8323 14.3817 11.6641 14.9998 11.6641C16.8408 11.6641 18.3332 13.1565 18.3332 14.9974C18.3332 16.8383 16.8408 18.3307 14.9998 18.3307C13.1589 18.3307 11.6665 16.8383 11.6665 14.9974C11.6665 14.3792 11.8348 13.8003 12.128 13.3041L9.99984 11.1759ZM4.99984 16.6641C5.92031 16.6641 6.6665 15.9179 6.6665 14.9974C6.6665 14.0769 5.92031 13.3307 4.99984 13.3307C4.07936 13.3307 3.33317 14.0769 3.33317 14.9974C3.33317 15.9179 4.07936 16.6641 4.99984 16.6641ZM14.9998 16.6641C15.9203 16.6641 16.6665 15.9179 16.6665 14.9974C16.6665 14.0769 15.9203 13.3307 14.9998 13.3307C14.0793 13.3307 13.3332 14.0769 13.3332 14.9974C13.3332 15.9179 14.0793 16.6641 14.9998 16.6641Z"
          fill="#FCFCFC"
        />
      </g>
    </svg>
  );
};
