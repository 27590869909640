import PropTypes from "prop-types";
import React from "react";
import { LineDarkmodeActionsMinus } from "../../icons/LineDarkmodeActionsMinus";
import { LineLightmodeCheck4 } from "../../icons/LineLightmodeCheck4";
import "./style.css";

export const InputCheckbox = ({ size, state, selected, darkmode, indeterminate, className, visible = true }) => {
  return (
    <div
      className={`input-checkbox ${size} state-${state} indeterminate-${indeterminate} selected-${selected} darkmode-${darkmode} ${className}`}
    >
      {(indeterminate || selected) && (
        <>
          <>
            {visible && (
              <>
                <>
                  {selected && (
                    <LineLightmodeCheck4 className="instance-node" color={darkmode ? "#14142B" : "#FCFCFC"} />
                  )}

                  {indeterminate && (
                    <LineDarkmodeActionsMinus className="instance-node" color={!darkmode ? "#FCFCFC" : "#14142B"} />
                  )}
                </>
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

InputCheckbox.propTypes = {
  size: PropTypes.oneOf(["large", "small"]),
  state: PropTypes.oneOf(["disabled", "focused", "hover", "default"]),
  selected: PropTypes.bool,
  darkmode: PropTypes.bool,
  indeterminate: PropTypes.bool,
  visible: PropTypes.bool,
};
