import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const savedDesignsSlice = createSlice({
  name: "savedDesigns",
  initialState,
  reducers: {
    designsList(state, action) {
        return state = [...action.payload];
    }
  },
});

// Action creators are generated for each case reducer function
export const { designsList } = savedDesignsSlice.actions;

export default savedDesignsSlice.reducer;
