import { configureStore } from '@reduxjs/toolkit';
import backgroundCategoriesSlice from './slices/backgroundCategoriesSlice';
import backgroundSlice from './slices/backgroundSlice';
import clipartCategoriesSlice from './slices/clipartCategoriesSlice';
import clipartsSlice from './slices/clipartsSlice';
import defaultTextStylesSlice from './slices/defaultTextStylesSlice';
import fontCategoriesSlice from './slices/fontCategoriesSlice';
import fontFileSlice from './slices/fontFileSlice';
import fontsSlice from './slices/fontsSlice';
import savedDesignsSlice from './slices/savedDesignsSlice';
import shapesSlice from './slices/shapesSlice';
import iconsCategoriesSlice from './slices/iconsCategoriesSlice';
import iconsSlice from './slices/iconsSlice';
import templatesCategoriesSlice from './slices/templatesCategoriesSlice';
import templatesSlice from './slices/templatesSlice';
import templatesSearchSlice from './slices/templatesSearchSlice';
import productSlice from './slices/productSlice';
import swagifyLoginSlice from './slices/swagifyLoginSlice';
import variantSlice from './slices/variantSlice';
import stateSlice from './slices/stateSlice';

export const store = configureStore({
  reducer: {
    clipartsCatetories: clipartCategoriesSlice,
    cliparts: clipartsSlice,
    defaultTextStyles: defaultTextStylesSlice,
    fontCategories: fontCategoriesSlice,
    fonts: fontsSlice,
    fontFile: fontFileSlice,
    backgroundCatetories: backgroundCategoriesSlice,
    background: backgroundSlice,
    savedDesigns: savedDesignsSlice,
    shapes: shapesSlice,
    iconsCategories: iconsCategoriesSlice,
    icons: iconsSlice,
    templatesCategories: templatesCategoriesSlice,
    templates: templatesSlice,
    templatesSearch: templatesSearchSlice,
    product: productSlice,
    swagifyLogin: swagifyLoginSlice,
    variant: variantSlice,
    state: stateSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
       immutableCheck: false,
       serializableCheck: false,
  })
})