import React from "react";
import { AddLine1 } from "../../icons/AddLine1";
import { ArrowDownSLine1 } from "../../icons/ArrowDownSLine1";
import "./Footer.css";

export const Footer = () => {
  return (
    <>
      <div className="footer-wrapper">
        <button className="btn-93">
          <div className="left-div-24 cursor-pointer">
            <div className="text-wrapper-179">Hide Products</div>
            <ArrowDownSLine1
              className="icon-instance-node-13"
              color="#FCFCFC"
            />
          </div>
        </button>
        <footer className="footer-12">
          <div className="container-42" />
          <img className="product-23" alt="Product" src="/img/product-1.png" />
          <img className="product-24" alt="Product" src="/img/product-2.png" />
          <div className="add-12">
            <div className="add-container" />
            <AddLine1 className="add-line-instance" color="#0576F0" />
          </div>
        </footer>
      </div>
    </>
  );
};
