import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const fontCategoriesSlice = createSlice({
  name: "fontCategories",
  initialState,
  reducers: {
    fontCategoriesList(state, action) {
        state.push(...action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { fontCategoriesList } = fontCategoriesSlice.actions;

export default fontCategoriesSlice.reducer;
