import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

const swagifyLoginSlice = createSlice({
  name: "swagifyLogin",
  initialState,
  reducers: {
    swagifyLoginData(state, action) {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { swagifyLoginData } = swagifyLoginSlice.actions;

export default swagifyLoginSlice.reducer;
