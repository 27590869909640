/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ArrowLeftSLine1 = ({ color = "#14142B", className }) => {
  return (
    <svg
      className={`arrow-left-s-line-1 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 24 27"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.8284 13.0919L15.7782 18.4916L14.364 20.0344L8 13.0919L14.364 6.14941L15.7782 7.69219L10.8284 13.0919Z"
        fill={color}
      />
    </svg>
  );
};

ArrowLeftSLine1.propTypes = {
  color: PropTypes.string,
};
