import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import DesignTool from "../pages/DesignTool/DesignTool";
import ErrorBoundary from "../pages/ErrorBoundary/ErrorBoundary";

const Routes = () => {
  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route exact path="/" element={<DesignTool />} />
          <Route exact path="/design-studio/:slug" element={<DesignTool />} />
          <Route exact path="*" element={<ErrorPage />} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};

export default Routes;
