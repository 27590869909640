/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArrowDownSLine3 = ({ className }) => {
  return (
    <svg
      className={`arrow-down-s-line-3 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.64604 11.3299L9.99959 11.6834L10.3531 11.3299L14.1244 7.55867L14.5958 8.03007L9.99959 12.6263L5.4034 8.03007L5.87481 7.55867L9.64604 11.3299Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
    </svg>
  );
};
