/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const AddLine1 = ({ color = "#14142B", className }) => {
  return (
    <svg
      className={`add-line-1 ${className}`}
      fill="none"
      height="34"
      viewBox="0 0 34 34"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.6112 15.6107V7.27734H18.3889V15.6107H26.7223V18.3885H18.3889V26.7218H15.6112V18.3885H7.27783V15.6107H15.6112Z"
        fill={color}
      />
    </svg>
  );
};

AddLine1.propTypes = {
  color: PropTypes.string,
};
