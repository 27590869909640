import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const backgroundSlice = createSlice({
  name: "background",
  initialState,
  reducers: {
    backgroundList(state, action) {
        state.push(...action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { backgroundList } = backgroundSlice.actions;

export default backgroundSlice.reducer;
