/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ArrowDownSLine = ({ color = "#FCFCFC", className }) => {
  return (
    <svg
      className={`arrow-down-s-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.99983 10.9763L14.1247 6.85156L15.3032 8.03007L9.99983 13.3334L4.69653 8.03007L5.87505 6.85156L9.99983 10.9763Z"
        fill={color}
      />
    </svg>
  );
};

ArrowDownSLine.propTypes = {
  color: PropTypes.string,
};
