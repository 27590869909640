import { createSlice } from '@reduxjs/toolkit'

const templatesSearchSlice = createSlice({
    name: 'designs',
    initialState: {
        designs: [],
        searchResults: []
    },
    reducers: {
        fetchDesigns: (state, action) => {
            state.designs = action.payload
        },
        searchDesigns: (state, action) => {
            const searchTerm = action.payload.toLowerCase()
            const regex = new RegExp(`\\b${searchTerm}\\b`, 'i')
            state.searchResults = state.designs.filter(design =>
                regex.test(design.keywords.toLowerCase())
            )
        }
    }
})

export const {fetchDesigns, searchDesigns} = templatesSearchSlice.actions
export default templatesSearchSlice.reducer
