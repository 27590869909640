import {createSlice} from "@reduxjs/toolkit"

const initialState = []

const iconsCategoriesSlice = createSlice({
    name: "iconsCategories",
    initialState,
    reducers: {

        iconsCategoriesList(state, action) {
            if (Array.isArray(action.payload)) {
                return action.payload
            } else {
                console.error('Payload is not an array:', action.payload);
            }
        }
    }
})

export const {iconsCategoriesList} = iconsCategoriesSlice.actions
export default iconsCategoriesSlice.reducer