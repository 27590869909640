/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Divider6 = ({ className }) => {
  return (
    <svg
      className={`divider-6 ${className}`}
      fill="none"
      height="46"
      viewBox="0 0 2 46"
      width="2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M1 0V46" stroke="#4E4B66" />
    </svg>
  );
};
