import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const clipartCategoriesSlice = createSlice({
  name: "clipartsCatetories",
  initialState,
  reducers: {
    clipartsCategoriesList(state, action) {
        state.push(...action.payload);
        // return action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { clipartsCategoriesList } = clipartCategoriesSlice.actions;

export default clipartCategoriesSlice.reducer;