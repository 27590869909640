import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const clipartsSlice = createSlice({
  name: "cliparts",
  initialState,
  reducers: {
    clipartsList(state, action) {
        state.push(...action.payload);
        // return [...state, ...action.payload]
    }
  },
});

// Action creators are generated for each case reducer function
export const { clipartsList } = clipartsSlice.actions;

export default clipartsSlice.reducer;