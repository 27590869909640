import { fabric } from "fabric";
import { v4 as uuid } from "uuid";

var panning = false;
var panMode = false;
var _clipboard = null;
var masks = {};
var state_front;
var state_back;
var undo_front = [];
var redo_front = [];
var undo_back = [];
var redo_back = [];
var disableUndo_front = true;
var disableRedo_front = true;
var disableUndo_back = true;
var disableRedo_back = true;
var drawLine = false;
var line;
var line_id;
var drawEllipse = false;
var ellipse;
var origXE; 
var origYE;
var ellipse_id;
var drawCircle;
var circle; 
var origXC;
// var origYC;
var circle_id;

const deleteIcon =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi4zMjIiIGhlaWdodD0iMjIuMzU4IiB2aWV3Qm94PSIwIDAgMjIuMzIyIDIyLjM1OCI+CiAgPGcgaWQ9Ikdyb3VwXzMzIiBkYXRhLW5hbWU9Ikdyb3VwIDMzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4OTA0IC02MjE4KSI+CiAgICA8cGF0aCBpZD0iUGF0aF85NCIgZGF0YS1uYW1lPSJQYXRoIDk0IiBkPSJNMjIuMzIyLDExLjE2OUExMS4xNjIsMTEuMTYyLDAsMSwxLDExLjEsMCwxMS4xMzEsMTEuMTMxLDAsMCwxLDIyLjMyMiwxMS4xNjlNMTEuMjE2LDEzLjUyN2wzLjUyNCwzLjUyNSwyLjMyMS0yLjM0Ni0zLjU0OS0zLjUyOCwzLjUyNi0zLjUwN0wxNC42ODUsNS4zMDksMTEuMTYyLDguODYzLDcuNjMzLDUuMzM5LDUuMjcyLDcuNzA2LDguNzc4LDExLjIsNS4yNjcsMTQuNzEybDIuNCwyLjM3NywzLjU1Mi0zLjU2MSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg5MDQgNjIxOCkiIGZpbGw9InJlZCIvPgogICAgPHBhdGggaWQ9IlBhdGhfOTUiIGRhdGEtbmFtZT0iUGF0aCA5NSIgZD0iTTEwOC44NzMsMTExLjk2N2wtMy41NTIsMy41NjEtMi40LTIuMzc3LDMuNTExLTMuNTExLTMuNTA2LTMuNDk1LDIuMzYxLTIuMzY4LDMuNTI5LDMuNTI1LDMuNTIzLTMuNTU0LDIuMzUyLDIuMzYyLTMuNTI2LDMuNTA3LDMuNTQ5LDMuNTI4LTIuMzIxLDIuMzQ2LTMuNTI0LTMuNTI1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTAwMS42NTcgNjExOS41NikiIGZpbGw9IiNmZmYiLz4KICA8L2c+Cjwvc3ZnPgo=";
var deleteImg = document.createElement("img");
deleteImg.src = deleteIcon;

const loadingIcon =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiByZ2IoMjU1LCAyNTUsIDI1NSk7IGRpc3BsYXk6IGJsb2NrOyBzaGFwZS1yZW5kZXJpbmc6IGF1dG87IGFuaW1hdGlvbi1wbGF5LXN0YXRlOiBydW5uaW5nOyBhbmltYXRpb24tZGVsYXk6IDBzOyIgd2lkdGg9IjIwMXB4IiBoZWlnaHQ9IjIwMXB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiPgo8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMyMmM0YjUiIHN0cm9rZS13aWR0aD0iOCIgc3Ryb2tlLWRhc2hhcnJheT0iNDIuNzY0ODIxMzcwNDQyNzEgNDIuNzY0ODIxMzcwNDQyNzEiIGQ9Ik0yNC4zIDMwQzExLjQgMzAgNSA0My4zIDUgNTBzNi40IDIwIDE5LjMgMjBjMTkuMyAwIDMyLjEtNDAgNTEuNC00MCBDODguNiAzMCA5NSA0My4zIDk1IDUwcy02LjQgMjAtMTkuMyAyMEM1Ni40IDcwIDQzLjYgMzAgMjQuMyAzMHoiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3R5bGU9InRyYW5zZm9ybTogc2NhbGUoMC4yNik7IHRyYW5zZm9ybS1vcmlnaW46IDUwcHggNTBweDsgYW5pbWF0aW9uLXBsYXktc3RhdGU6IHJ1bm5pbmc7IGFuaW1hdGlvbi1kZWxheTogMHM7Ij4KICA8YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSJzdHJva2UtZGFzaG9mZnNldCIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIGR1cj0iMXMiIGtleVRpbWVzPSIwOzEiIHZhbHVlcz0iMDsyNTYuNTg4OTI4MjIyNjU2MjUiIHN0eWxlPSJhbmltYXRpb24tcGxheS1zdGF0ZTogcnVubmluZzsgYW5pbWF0aW9uLWRlbGF5OiAwczsiPjwvYW5pbWF0ZT4KPC9wYXRoPgo8IS0tIFtsZGlvXSBnZW5lcmF0ZWQgYnkgaHR0cHM6Ly9sb2FkaW5nLmlvLyAtLT48L3N2Zz4=";
var loadingImg = document.createElement("img");
loadingImg.src = loadingIcon;

const scales = [
  { scale: 0.875, value: { x: 14, y: 16 } },
  { scale: 0.8571428571428571, value: { x: 12, y: 14 } },
  { scale: 0.8333333333333334, value: { x: 10, y: 12 } },
  { scale: 1, value: { x: 10, y: 10 } },
];

const getScale = (ab) => {
  let val = scales?.find((x) => parseFloat(x?.scale) === parseFloat(ab?.scale));
  return val || null;
};

const setCanvas = (canvas, imgInstance, pathOptions, areas) => {
  if (imgInstance) {
    let org_size = imgInstance.getOriginalSize();
    let metaData = null;

    let mask_areas = areas?.map((area, index) => {
      let width = (org_size?.width / 100) * parseFloat(area?.values?.width);
      let height = (org_size?.height / 100) * parseFloat(area?.values?.height);
      let left = (org_size?.width / 100) * parseFloat(area?.values?.left);
      let top = (org_size.height / 100) * parseFloat(area?.values?.top);
      let val = getScale(area?.position);
      let rect = null;
      let rect2 = null;
      if (area.emb) {
        rect = new fabric.Circle({
          width: width,
          height: height,
          radius: width / 2,
          selectable: false,
          evented: false,
          fill: "transparent",
          strokeDashArray: [10, 10],
          stroke: "rgba(255,255,255,0.75)",
          strokeWidth: 1,
        });

        rect.set({
          left: imgInstance?.left + left,
          top: imgInstance?.top + top,
        });

        rect2 = new fabric.Circle({
          width: width,
          height: height,
          radius: width / 2,
          selectable: false,
          evented: false,
          fill: "transparent",
          strokeDashArray: [10, 10],
          strokeDashOffset: 10,
          stroke: "rgba(0,0,0,0.75)",
          strokeWidth: 1,
        });

        rect2.set({
          left: imgInstance?.left + left,
          top: imgInstance?.top + top,
        });
      } else {
        rect = new fabric.Rect({
          width: width,
          height: height,
          selectable: false,
          evented: false,
          fill: "transparent",
          strokeDashArray: [10, 10],
          stroke: "rgba(255,255,255,0.75)",
          strokeWidth: 1,
        });

        rect.set({
          left: imgInstance?.left + left,
          top: imgInstance?.top + top,
        });

        rect2 = new fabric.Rect({
          width: width,
          height: height,
          selectable: false,
          evented: false,
          fill: "transparent",
          strokeDashArray: [10, 10],
          strokeDashOffset: 10,
          stroke: "rgba(0,0,0,0.75)",
          strokeWidth: 1,
        });

        rect2.set({
          left: imgInstance?.left + left,
          top: imgInstance?.top + top,
        });
      }

      if (val && !area.emb) {
        metaData = val?.value;
        const text = new fabric.Text(val.value.x + '"', {
          id: "bleed_text1",
          left: rect?.left + rect.width / 2 - 20,
          top: rect?.top - 40,
          fontSize: 30,
          fontFamily: "Open Sans",
          fill: "red",
          selectable: false,
          evented: false,
          visible: false
        });
        const text2 = new fabric.Text(val.value.y + '"', {
          id: "bleed_text2",
          left: rect?.left - 40,
          top: rect?.top + rect.height / 2 - 10,
          fontSize: 30,
          angle: -90,
          fontFamily: "Open Sans",
          fill: "red",
          selectable: false,
          evented: false,
          visible: false
        });
        canvas.add(text);
        canvas.add(text2);
      }

      if (index === 0) {
        let path = null;
        let bleedPath = null;
        if (area?.emb) {
          path = new fabric.Circle({
            left: left,
            top: top,
            width: width,
            radius: width / 2,
            height: height,
          });
          path.set({
            left: imgInstance?.left + left,
            top: imgInstance?.top + top,
          });
          bleedPath = new fabric.Path(
            `M ${path.left + path.radius} ${path.top} A ${path.radius} ${
              path.radius
            } 0 0 0 ${path.left - path.radius} ${path.top} A ${path.radius} ${
              path.radius
            } 0 0 0 ${path.left + path.radius} ${path.top}`,
            {
              left: imgInstance?.left + left,
              top: imgInstance?.top + top,
              opacity: 0.0001,
            }
          );
        } else {
          path = new fabric.Rect({
            left: left,
            top: top,
            width: width,
            height: height,
          });
          path.set({
            left: imgInstance?.left + left,
            top: imgInstance?.top + top,
          });
          bleedPath = new fabric.Path(
            `M ${rect.left} ${rect.top} L ${rect.left + rect.width} ${
              rect.top
            } L ${rect.left + rect.width} ${rect.top + rect.height} L ${
              rect.left
            } ${rect.top + rect.height} Z`,
            {
              left: imgInstance?.left + left,
              top: imgInstance?.top + top,
              opacity: 0.0001,
            }
          );
        }

        bleedPath.set({
          ...pathOptions,
          id: "bleeding_area",
          metaData: metaData,
          fill: "black",
        });
        canvas.add(bleedPath);
        masks[canvas?.id] = bleedPath;
      }
      return [rect, rect2];
    });

    let main_mask_area = new fabric.Group(mask_areas[0], {
      id: "guides",
      evented: false,
      selectable: false,
    });
    // canvas.add(main_mask_area)
    return main_mask_area;
  }
};

export const initializeCanvas = ({
  canvas,
  wrapper,
  areas,
  onZoom,
  onElementsChange,
  setImgLoading,
}) => {
  if (canvas && areas) {
    return new Promise((resolve, reject) => {
      let prevCanvas =
        canvas.getObjects()?.find((x) => x.id === "wrapper") || null;
      const pathOptions = {
        absolutePositioned: true,
        hasControls: false,
        hasRotatingPoint: false,
        selectable: false,
        evented: false,
      };
      if (!prevCanvas) {
        setImgLoading(true);
        new fabric.Image.fromURL(wrapper, function (imgInstance) {
          // const imgInstance = new fabric.Image(img,  {...pathOptions, id: 'wrapper', metaData: { name: 'wrapper'} });
          imgInstance.set({
            ...pathOptions,
            id: "wrapper",
            metaData: { name: "wrapper" },
          });
          canvas.add(imgInstance);
          canvas.centerObject(imgInstance);
          let masking_area = setCanvas(canvas, imgInstance, pathOptions, areas);
          canvas.add(masking_area);

          var bounds = canvas.getObjects().reduce(function (bounds, obj) {
            return bounds || obj.getBoundingRect();
          }, null);

          registerEvents(canvas, onZoom, onElementsChange, setImgLoading);

          var canvasWidth = canvas.getWidth();
          var canvasHeight = canvas.getHeight();
          var objectWidth = bounds.width;
          var objectHeight = bounds.height;

          let zoom = 0.25;
          if (objectWidth > canvasWidth || objectHeight > canvasHeight) {
            zoom = Math.min(
              canvasWidth / objectWidth,
              canvasHeight / objectHeight
            );
          } else if (objectWidth < canvasWidth && objectHeight < canvasHeight) {
            zoom = Math.min(
              canvasWidth / objectWidth,
              canvasHeight / objectHeight
            );
          }

          if (zoom > 1.5) zoom = 1.5;
          if (zoom < 0.1) zoom = 0.1;
          canvas.zoomToPoint(
            new fabric.Point(canvas.width / 2, canvas.height / 2),
            zoom
          );
          onZoom(zoom);
          setImgLoading(false);
          resolve(canvas.id);
          canvas.renderAll();
        });

        // const img = new Image();
        // img.src = wrapper

        // img.onload = function() {

        // }
      } else {
        let prevObjs =
          canvas
            .getObjects()
            ?.filter(
              (x) =>
                x.id === "bleeding_area" ||
                x?.id === "guides" ||
                x?.metaData?.name === "object"
            ) || [];
        if (prevObjs.length > 0) {
          prevObjs.map((obj) => {
            return canvas.remove(obj);
          });
        }

        let imgInstance = prevCanvas;
        // setCanvas(canvas, imgInstance, pathOptions, areas)
        let masking_area = setCanvas(canvas, imgInstance, pathOptions, areas);
        canvas.add(masking_area);
        resolve(canvas.id);
        canvas.renderAll();
      }
    });
  }
};

export const updateWrapper = (canvas, newImg, setImgLoading) => {
  if (canvas && newImg) {
    setImgLoading(true);
    let imageInstance = canvas?.getObjects()?.find((x) => x?.id === "wrapper");
    if (imageInstance) {
      imageInstance.setSrc(newImg?.link, function (img) {
        canvas.renderAll();
        setImgLoading(false);
      });
    }
  }
};

export const registerEvents = (
  canvas,
  onZoom,
  onElementsChange,
  setImgLoading
) => {
  function handleObjects(e, type) {
    let all_elements =
      canvas.getObjects()?.filter((x) => x?.metaData?.name === "object") || [];
    let new_array = all_elements?.map((x) => ({
      ...x?.metaData,
      print_area: canvas?.getObjects()?.find((x) => x.id === "bleeding_area"),
      width: x?.width * x?.scaleX,
      height: x?.height * x?.scaleY,
      left: x?.left,
      top: x?.top,
      selected: e?.target?.metaData?.id === x?.metaData?.id,
    }));

    if (type === "add") {
      setImgLoading(false);
    }

    if (type === "modify") {
      console.log("Modified", e.target);
    }
    if (type === "remove") {
      let index = new_array.findIndex((x) => x?.id === e.target?.metaData?.id);
      if (index > -1) {
        new_array.splice(index, 1);
      }
    }
    onElementsChange(new_array.reverse());
  }

  function handleSelection(e) {
    let all_elements =
      canvas.getObjects()?.filter((x) => x?.metaData?.name === "object") || [];
    let selected_elements =
      e?.selected
        ?.filter((x) => x?.metaData?.name === "object")
        ?.map((y) => y?.metaData?.id) || [];
    let new_array = [];
    new_array = all_elements?.map((x) => ({
      ...x?.metaData,
      print_area: canvas?.getObjects()?.find((x) => x.id === "bleeding_area"),
      width: x?.width * x?.scaleX,
      height: x?.height * x?.scaleY,
      left: x?.left,
      top: x?.top,
      selected: selected_elements?.includes(x?.metaData?.id),
    }));
    
    let item = canvas.getActiveObject();
    if (item) {
      item.set({
        cornerSize: 9,
        transparentCorners: false,
        cornerColor: "white",
        cornerStrokeColor: "#29CC79",
        cornerStyle: "rect",                
        borderColor: "#29CC79"
      });
      item.setControlVisible("ml", false);
      item.setControlVisible("mr", false);
      item.setControlVisible("mt", false);
      item.setControlVisible("mb", false);

      item.controls.deleteControl = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 12, 
        offsetX: 0,
        cursorStyle: "pointer",
        mouseUpHandler: deleteObject,
        render: renderIcon,
        cornerSize: 18,
      });
    
      var controlsUtils = fabric.controlsUtils;

      item.controls.mtr = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: -10,                                
        cursorStyleHandler: controlsUtils.rotationStyleHandler,
        actionHandler: controlsUtils.rotationWithSnapping,
        withConnection: false,
        cursorStyle: `url("/rotate.png"), auto`,
        actionName: "rotate",
        render: function (ctx, left, top) {
          ctx.save();
          ctx.fillStyle = "#29CC79";
          ctx.strokeStyle = "#29CC79";
          ctx.translate(left, top);
          ctx.beginPath();
          ctx.arc(0, 0, 5, 0, 2 * Math.PI);
          ctx.fill();
          ctx.stroke();
          ctx.restore();
        },
      });
    
      function deleteObject(eventData, transform) {
        removeObject(canvas);
        canvas.requestRenderAll();
      }
    
      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size);
        ctx.restore();
      }

      if (item.lock === true) {
        item.setControlVisible("deleteControl", false);
      } else {
        item.setControlVisible("deleteControl", true);
      }
      
    }
    onElementsChange(new_array.reverse());
  }

  canvas.on("object:added", function (e) {
    handleObjects(e, "add");
  });
  canvas.on("object:removed", function (e) {
    handleObjects(e, "remove");
  });

  canvas.on({
    "selection:updated": handleSelection,
    "selection:created": handleSelection,
  });

  canvas.on("object:modified", function (e) {
    handleObjects(e, "modify");
    save(canvas);
  });

  canvas.on("mouse:wheel", function (opt) {
    // canvas.discardActiveObject()
    // setSelectedObject(null)
    var delta = opt.e.deltaY;
    var zoom = canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 1.5) zoom = 1.5;
    if (zoom < 0.1) zoom = 0.1;
    // canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    // object zoom to center always
    canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), zoom);
    onZoom(zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
  });

  canvas.on("mouse:down", function (e) {
    panning = true;
    if (drawLine) {
      let pointer = canvas.getPointer(e);
      let points = [pointer.x, pointer.y, pointer.x, pointer.y];
      line = new fabric.Line(points, {
          strokeWidth: 5,
          stroke: '#000000',
          type: 'line'
      });
      line.set({
        metaData: { id: line_id, name: "object", type: "line" }
      });
      canvas.add(line);
    }

    if (drawEllipse) {
      let pointer = canvas.getPointer(e);
      origXE = pointer.x;
      origYE = pointer.y;
      ellipse = new fabric.Ellipse({
          left: origXE,
          top: origYE,
          originX: 'left',
          originY: 'top',
          rx: pointer.x-origXE,
          ry: pointer.y-origYE,
          angle: 0,
          fill: '',
          stroke: '#000000',
          strokeWidth: 5,
          type: 'ellipse'
      });
      ellipse.set({
        metaData: { id: ellipse_id, name: "object", type: "ellipse" }
      });
      canvas.add(ellipse);
    }

    if(drawCircle) {
      var pointer = canvas.getPointer(e);
      origXC = pointer.x;
      // origYC = pointer.y;
      circle = new fabric.Circle({
        left: pointer.x,
        top: pointer.y,
        radius: 1,
        strokeWidth: 5,
        stroke: '#000000',
        fill: '',
        originX: 'center',
        originY: 'center',
        type: 'circle'
      });
      circle.set({
        metaData: { id: circle_id, name: "object", type: "circle" }
      });
      canvas.add(circle);
    }
    canvas.renderAll();
  });

  canvas.on("mouse:up", function (e) {
    panning = false;
    if (canvas.isDrawingMode) {
      canvas.isDrawingMode = false;
    }
    if (drawLine) {
      drawLine = false;
      line = null;
      save(canvas);
    }
    if (drawEllipse) {
      drawEllipse = false;
      ellipse = null;
      save(canvas);
    }
    if(drawCircle) {
      drawCircle = false;
      circle = null;
      save(canvas);
    }
    canvas.renderAll();
  });

  canvas.on("mouse:move", function (e) {
    if (panning && e && e.e && panMode) {
      canvas.discardActiveObject();
      canvas.selection = false;
      let moveX = e.e.movementX;
      let moveY = e.e.movementY;

      canvas.relativePan(new fabric.Point(moveX, moveY));
      e.e.preventDefault();
      e.e.stopPropagation();
    }
    canvas.renderAll();
    if (drawLine && line) {
      let pointer = canvas.getPointer(e);
      line.set({ x2: pointer.x, y2: pointer.y });
      addControls(canvas, line);
      canvas.renderAll();
    }
    if (drawEllipse && ellipse) {
      let pointer = canvas.getPointer(e);
      let rx = Math.abs(origXE - pointer.x)/2;
      let ry = Math.abs(origYE - pointer.y)/2;
      if (rx > ellipse.strokeWidth) {
        rx -= ellipse.strokeWidth/2
      }
      if (ry > ellipse.strokeWidth) {
        ry -= ellipse.strokeWidth/2
      }
      ellipse.set({ rx: rx, ry: ry});
      
      if(origXE>pointer.x){
          ellipse.set({originX: 'right' });
      } else {
          ellipse.set({originX: 'left' });
      }
      if(origYE>pointer.y){
          ellipse.set({originY: 'bottom'  });
      } else {
          ellipse.set({originY: 'top'  });
      }
      addControls(canvas, ellipse);
      canvas.renderAll();
    }
    if (drawCircle && circle) {
      let pointer = canvas.getPointer(e);
      circle.set({
        radius: Math.abs(origXC - pointer.x)
      });
      addControls(canvas, circle);
      canvas.renderAll();
    }
  });

  canvas.on("text:changed", adjustTextWidth);
  
  canvas.on('object:scaling', (event) => {
    var obj = event.target;
    obj.setCoords();
    if ((obj.getBoundingRect(true, true).top + 4.5) < masks[canvas?.id].top || 
      (obj.getBoundingRect(true, true).top + 4.5 + obj.getBoundingRect(true, true).height  > masks[canvas?.id].height + masks[canvas?.id].top)) {
      obj.set({lockScalingY: true});
    } else {
      obj.set({lockScalingY: false, lockRotation: false});
    }
    if ((obj.getBoundingRect(true, true).left + 4.5) < masks[canvas?.id].left || 
      (obj.getBoundingRect(true, true).left + 4.5 + obj.getBoundingRect(true, true).width > masks[canvas?.id].width + masks[canvas?.id].left)) {
      obj.set({lockScalingX: true});
    } else {
      obj.set({lockScalingX: false, lockRotation: false});
    }
    obj.setCoords();

    if (obj && obj?.metaData && obj?.metaData?.type === 'text' && obj?.metaData?.textType === 'h') {
      updateElement({
        fontSize: Number((obj.fontSize * obj.scaleX).toFixed(0)),
        width: Number(obj.width * obj.scaleX),
        height: Number(obj.height * obj.scaleY)
      }, canvas);
    }

    fabric.Object.prototype.noScaleCache = false;
  });

  canvas.on("object:moving", (e) => {
      var obj = e.target;
      obj.setCoords(); 
      // top
      if (obj.getBoundingRect(true, true).top < masks[canvas?.id].top) {
        const h = obj.originY === 'center' ? obj.getBoundingRect(true, true).height / 2 : obj.getBoundingRect(true, true).height;
        obj.set({top: Math.floor(masks[canvas?.id].top + h + 4.5)});
      }
      // left
      if (obj.getBoundingRect(true, true).left < masks[canvas?.id].left) {
        const w = obj.originX === "center" ? obj.getBoundingRect(true, true).width / 2  : obj.getBoundingRect(true, true).width;
        obj.set({left: Math.floor(masks[canvas?.id].left + w + 4.5)});
      }
      // right
      if ((obj.getBoundingRect(true, true).left + obj.getBoundingRect(true, true).width > masks[canvas?.id].width + masks[canvas?.id].left)) {
        const w = obj.originX === "center" ? obj.getBoundingRect(true, true).width / 2  : obj.getBoundingRect(true, true).width;
        obj.set({left: Math.floor(masks[canvas?.id].left + masks[canvas?.id].width - w - 4.5)});
      }
      // bottom
      if ((obj.getBoundingRect(true, true).top + obj.getBoundingRect(true, true).height  > masks[canvas?.id].height + masks[canvas?.id].top)) {
        const h = obj.originY === 'center' ? obj.getBoundingRect(true, true).height / 2 : obj.getBoundingRect(true, true).height;
        obj.set({top: Math.floor(masks[canvas?.id].top + masks[canvas?.id].height - h - 4.5)});
      }
      obj.setCoords();
  });

  canvas.on("object:rotating", (e) => {
    var obj = e.target;
    obj.setCoords();
    if ((obj.getBoundingRect(true, true).top + 4.5) < masks[canvas?.id].top || 
      (obj.getBoundingRect(true, true).top + 4.5 + obj.getBoundingRect(true, true).height  > masks[canvas?.id].height + masks[canvas?.id].top)) {
      obj.set({lockRotation: true});
    } else {
      obj.set({lockRotation: false, lockScalingX: false});
    }
    if ((obj.getBoundingRect(true, true).left + 4.5) < masks[canvas?.id].left || 
      (obj.getBoundingRect(true, true).left + 4.5 + obj.getBoundingRect(true, true).width > masks[canvas?.id].width + masks[canvas?.id].left)) {
      obj.set({lockRotation: true});
    } else {
      obj.set({lockRotation: false, lockScalingX: false});
    }
    obj.setCoords();
  })

};

async function removeObject(canvas) {
  let activeObjects = canvas.getActiveObjects();
  if (activeObjects.length) {
    let result = await window.confirm(
      "Do you want to delete the selected item?",
      "Remove Item"
    );
    if (result) {
      activeObjects.forEach(function (object) {
        canvas.remove(object);
      });
      canvas.discardActiveObject();
      canvas.renderAll();
      save(canvas);
    }
  } else {
    alert("Please select the drawing to delete");
  }
}

/* function renderIcon(ctx, left, top, styleOverride, fabricObject) {
  var size = this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size);

  ctx.restore();
} */

const setObjectProps = (imgInstance, canvas, type, position, zoom) => {
  /* imgInstance.setControlVisible("ml", false);
  imgInstance.setControlVisible("mr", false);
  imgInstance.setControlVisible("mt", false);
  imgInstance.setControlVisible("mb", false);

  imgInstance.controls.deleteControl = new fabric.Control({
    x: 0,
    y: 0.5,
    offsetY: 12,
    offsetX: 0,
    cursorStyle: "pointer",
    mouseUpHandler: deleteObject,
    render: renderIcon,
    cornerSize: 18,
  });

  var controlsUtils = fabric.controlsUtils;

  imgInstance.controls.mtr = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetY: -15,
    cursorStyleHandler: controlsUtils.rotationStyleHandler,
    actionHandler: controlsUtils.rotationWithSnapping,
    withConnection: false,
    cursorStyle: `url("/rotate.png"), auto`,
    actionName: "rotate",
    render: function (ctx, left, top) {
      ctx.save();
      ctx.fillStyle = "#29CC79";
      ctx.strokeStyle = "#29CC79";
      ctx.translate(left, top);
      ctx.beginPath();
      ctx.arc(0, 0, 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
      ctx.restore();
    },
  });

  function deleteObject(eventData, transform) {
    removeObject(canvas);
    canvas.requestRenderAll();
  } */

  let mask = masks[canvas?.id];

  if (position) {
    imgInstance.set({
      width: position?.width,
      height: position?.height,
      clipPath: mask,
      left: mask.left + position.left,
      top: mask.top + position.top,
      scaleX: position?.scaleX,
      scaleY: position?.scaleY,
      // zoomX: position?.zoomX,
      // zoomY: position?.zoomY,
      angle: position?.angle,
    });
  } else {
    if (type !== "background") {
      var scale = Math.min(400 / imgInstance.width, 400 / imgInstance.height);
      imgInstance.scaleX = scale;
      imgInstance.scaleY = scale;
      /* imgInstance.left = mask.left + (mask.width - imgInstance.width * scale) / 2;
      imgInstance.top = mask.top + (mask.height - imgInstance.height * scale) / 2; */
    }
  }

  imgInstance.set({ 
    left: mask.width / 2 + mask.left,
    top: mask.height / 2 + mask.top
  });
  imgInstance.setCoords();

  canvas.add(imgInstance);

  if (!panMode) {
    canvas.discardActiveObject().setActiveObject(imgInstance);
  }

  canvas.renderAll();

  if (type === "background") {
    let all_elements =
      canvas.getObjects()?.filter((x) => x?.metaData?.name === "object") || [];
    all_elements = all_elements.reverse();
    const fromIndex = all_elements.findIndex((elem) => elem?.metaData?.type === "background");
    const toIndex = all_elements.length - 1;
    if (fromIndex !== toIndex) {
      const allLayers = [...all_elements];
      const item = allLayers.splice(fromIndex, 1)[0];
      allLayers.splice(toIndex, 0, item);
      let non_objs = canvas?.getObjects()?.filter((x) => !x.selectable);
      allLayers.reverse()?.map((ele, index) => {
        let obj = canvas
          .getObjects()
          .find((x) => x?.metaData && x?.metaData?.id === ele?.metaData?.id);
        if (obj) {
          canvas.moveTo(obj, non_objs.length + index);
        }
        if (index === allLayers?.length - 1) {
          canvas.fire("object:modified");
        }
        return null;
      });
      if (!panMode) {
        canvas.discardActiveObject().setActiveObject(imgInstance);
      }
      canvas.renderAll();
    }  
  }

  // gutter?.bringToFront()
  return imgInstance;
  // print_gutter?.bringToFront()
};

export const addToCanvas = (
  id,
  imgPath = "03.jpg",
  type = "image",
  canvas,
  setLoading,
  position = null,
  zoom
) => {
  let mask = masks[canvas?.id];
  let imprint =
    canvas.getObjects()?.find((x) => x?.id === "bleeding_area") || null;

  if (imprint && mask) {
    setLoading(true);
    let settings = {
      preserveAspectRatio: "xMidYMid meet",
      originX: "center",
      originY: "center",
      metaData: { id: id, name: "object", src: imgPath, type: type, opacity: 1, angle: 0, lock: false },
      evented: true,
      clipPath: mask,
      cornerSize: 9,
      transparentCorners: false,
      cornerColor: "white",
      cornerStrokeColor: "#29CC79",
      cornerStyle: "rect",
      borderColor: "#29CC79",
      lockScalingFlip: true,
      centeredScaling: true
    };

    if (type === "background") {
      let backImage = canvas.getObjects()?.find((x) => x?.metaData?.name === "object" && x?.metaData?.type === "background") || null;
      if (backImage) {
        canvas.remove(backImage);
      }
      settings.width = imprint?.width;
      settings.height = imprint?.height;
      settings.left = imprint?.left;
      settings.top = imprint?.top;
    }

    if (imgPath.includes(".svg")) {
      var group = [];
      fabric.loadSVGFromURL(
        imgPath,
        function (svgImg, options) {
          var imgInstance = new fabric.Group(group);
          imgInstance.set(settings);
          imgInstance.set({
            crossOrigin: "Anonymous"
          })
          let instance = setObjectProps(imgInstance, canvas, type, position, zoom);
          save(canvas);
          return instance;
          // canvas.renderAll()
        },
        function (item, object) {
          object.set("id", item.getAttribute("id"));
          group.push(object);
        }
      );
    } else {
      // const img = new Image();
      // img.src = imgPath
      // img.onload = function() {
      new fabric.Image.fromURL(
        imgPath,
        function (imgInstance) {
          imgInstance.set(settings);
          let instance = setObjectProps(imgInstance, canvas, type, position, zoom);
          save(canvas);
          return instance;
        },
        {
          crossOrigin: "Anonymous",
        }
      );
      // const imgInstance = new fabric.Image(img,  settings)

      // }
    }
  } else {
    // alert("No imprined area defined");
    // setLoading(false)
  }
};

export const sortElements = (elements, canvas) => {
  let non_objs = canvas?.getObjects()?.filter((x) => !x.selectable);
  elements.reverse()?.map((ele, index) => {
    let obj = canvas
      .getObjects()
      .find((x) => x?.metaData && x?.metaData?.id === ele?.id);
    if (obj) {
      canvas.moveTo(obj, non_objs.length + index);
      // obj.fire('modified')
    }
    if (index === elements?.length - 1) {
      canvas.fire("object:modified");
    }
    return null;
  });
};

export const deleteElement = (id, canvas) => {
  let item = canvas.getObjects()?.find((x) => x?.metaData?.id === id);
  if (item) {
    canvas.remove(item);
    // canvas.requestRenderAll();
    canvas.renderAll();
    save(canvas);
  }
};

export const updateElement = (data, canvas) => {
  let item = canvas.getActiveObject();
  if (item) {
      // top
      if ((item.getBoundingRect(true, true).top < (masks[canvas?.id].top + 4.5)) && 
        data?.fill === undefined && data?.stroke === undefined && 
        data?.strokeDashArray === undefined && data?.strokeDashOffset === undefined &&
        data?.textType === undefined && data?.opacity === undefined) {
        return;
      }
      // left
      if ((item.getBoundingRect(true, true).left < (masks[canvas?.id].left + 4.5)) && 
        data?.fill === undefined && data?.stroke === undefined && 
        data?.strokeDashArray === undefined && data?.strokeDashOffset === undefined &&
        data?.textType === undefined && data?.opacity === undefined) {
        return;
      }
      // right
      if ((item.getBoundingRect(true, true).left + item.getBoundingRect(true, true).width > (masks[canvas?.id].width + masks[canvas?.id].left - 4.5)) && 
        data?.fill === undefined && data?.stroke === undefined && 
        data?.strokeDashArray === undefined && data?.strokeDashOffset === undefined &&
        data?.textType === undefined && data?.opacity === undefined) {
        return;
      }
      // bottom
      if ((item.getBoundingRect(true, true).top + item.getBoundingRect(true, true).height  > (masks[canvas?.id].height + masks[canvas?.id].top - 4.5)) && 
        data?.fill === undefined && data?.stroke === undefined && 
        data?.strokeDashArray === undefined && data?.strokeDashOffset === undefined &&
        data?.textType === undefined && data?.opacity === undefined) {
        return;
      }

      if (item?.metaData && item?.metaData?.type === "text" && (data?.textType === "h" || data?.textType === "v")) {
          if (item.hasOwnProperty("path")) {
              if (item.hasOwnProperty("pathAlign")) {
                item.set({
                  pathAlign: "baseline"
                })
                canvas.renderAll();
              }
              delete item.path;
              delete item.metaData.path;
          }
          if (item.hasOwnProperty("pathSide")) {
              delete item.pathSide;
              delete item.metaData.pathSide;
          }
          if (item.hasOwnProperty("pathStartOffset")) {
              delete item.pathStartOffset;
              delete item.metaData.pathStartOffset;
          }
          if (item.hasOwnProperty("radius")) {
              delete item.radius;
              delete item.metaData.radius;
          }
          if (item.hasOwnProperty("pathAlign")) {
              delete item.pathAlign;
              delete item.metaData.pathAlign;
          }
      } else if (item?.metaData && item?.metaData?.type === "text" && data?.textType === "c" ) {
          let id = uuid();
          let zoom = canvas.getZoom();
          canvas.remove(item);
          addText(id, data?.text, canvas, zoom, data?.textType, data?.fontSize, data?.fontFamily, data?.fontWeight, data?.fontStyle);
          let activeItem = canvas.getActiveObject();
          activeItem.set({
            ...data
          });
          canvas.renderAll();
          save(canvas);
          return ;
      } else if (item?.metaData && (item?.metaData?.type === "image" || item?.metaData?.type === "clipart" || item?.metaData?.type === "background" || item?.metaData?.type === "icon" || item?.metaData?.type === "shape")) {
          if (data.hasOwnProperty("width")) {
            data.width = data.width / item.scaleX;
          }
          if (data.hasOwnProperty("height")) {
            data.height = data.height / item.scaleY;
          }
          item.set({
            metaData: { ...item.metaData, ...data },
            ...data,
          });
          canvas.renderAll();
          save(canvas);
          return;
      }
      item.set({
        metaData: { ...item.metaData, ...data },
        ...data,
      });
      canvas.renderAll();
      if (item?.metaData && item?.metaData?.type === "text" && (item?.metaData?.textType === "h" || item?.metaData?.textType === "v")) {
        const textLinesMaxWidth = item.textLines.reduce((max, _, i) => Math.max(max, item.getLineWidth(i)), 0);
        item.set({width: textLinesMaxWidth});
        canvas.renderAll();
      }
      save(canvas);
  }
};

export const setActiveElement = (id, canvas) => {
  let item = canvas.getObjects()?.find((x) => x?.metaData?.id === id);
  if (item) {
    canvas.setActiveObject(item);
    // canvas.requestRenderAll();
    canvas.renderAll();
  }
};

export const lockUnlockElement = (data, canvas) => {
  let item = canvas.getActiveObject();
  if(item){
      item.set({
          metaData: {...item.metaData, ...data},
          ...data
      });
      canvas.discardActiveObject();
      canvas.setActiveObject(item);
      canvas.renderAll();
      // save(canvas);
  }
}

export const rotateElement = (data, canvas) => {
  let item = canvas.getActiveObject();
  if (item) {
    // top
    if (item.getBoundingRect(true, true).top < (masks[canvas?.id].top + 4.5)) {
      return;
    }
    // left
    if (item.getBoundingRect(true, true).left < (masks[canvas?.id].left + 4.5)) {
      return;
    }
    // right
    if (item.getBoundingRect(true, true).left + item.getBoundingRect(true, true).width > (masks[canvas?.id].width + masks[canvas?.id].left - 4.5)) {
      return;
    }
    // bottom
    if (item.getBoundingRect(true, true).top + item.getBoundingRect(true, true).height  > (masks[canvas?.id].height + masks[canvas?.id].top - 4.5)) {
      return;
    }
    item.rotate(data.angle);
    item.set({
        metaData: {...item.metaData, ...data},
        ...data
    });
    canvas.renderAll();
    save(canvas);
  }
}

export const cutElement = (canvas) => {
  let item = canvas.getActiveObject();
  if (item) {
    item.clone((cloned) => {
      _clipboard = cloned;
      _clipboard.metaData = {...item.metaData}
    });
    canvas.remove(item);
  }
}

export const copyElement = (canvas) => {
  let item = canvas.getActiveObject();
  if (item) {
    item.clone((cloned) => {
      _clipboard = cloned;
      _clipboard.metaData = {...item.metaData}
    });
  }
}

export const pasteElement = (id, canvas) => {
  let mask = masks[canvas?.id];
  const isEmpty =  (_clipboard === null) ? true : false;
  if (!isEmpty) {
    _clipboard.clone((clonedObj) => {
      canvas.discardActiveObject();

      clonedObj.set({
        clipPath: mask,
        left: (clonedObj?.canvas?.id === canvas?.id) ? clonedObj.left + 10 : clonedObj.left,
        top: (clonedObj?.canvas?.id === canvas?.id) ? clonedObj.top + 10 : clonedObj.top,
        evented: true,
        metaData: {..._clipboard.metaData, id: id},
      });

      if (clonedObj.type === 'activeSelection') {
        clonedObj.canvas = canvas;
        clonedObj.forEachObject((obj) => {
          canvas.add(obj);
        });
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      _clipboard.top += 10;
      _clipboard.left += 10;
      canvas.setActiveObject(clonedObj);
      canvas.renderAll();
    });
    save(canvas);
  }
}

export const alignElement = (align, canvas) => {
  let item = canvas.getActiveObject();
  const bleeding_area = canvas?.getObjects()?.find((x) => x.id === "bleeding_area");
  if (item) {
    switch(align) {
      case "hl" : if (bleeding_area) {
                    if (item.type === "activeSelection") {
                      item.set({
                        left: bleeding_area?.left
                      });
                      let groupWidth = item.getScaledWidth();
                      item.forEachObject((object) => {
                        object.set({
                          left: -(groupWidth / 2),
                          originX: 'left'
                        });  
                      });
                    } else if (item?.metaData && item?.metaData?.type === 'text') {
                      item.set({
                        left: bleeding_area?.left + (item.getScaledWidth() / 2)
                      });
                    } else {
                      item.set({
                        left: bleeding_area?.left
                      });
                    }
                  } else {
                    item.set({
                      left: (item.getScaledWidth() / 2)
                    });
                    if (item.type === "activeSelection") {
                      let groupWidth = item.getScaledWidth();
                      item.forEachObject((object) => {
                        object.set({
                          left: -(groupWidth / 2),
                          originX: 'left'
                        });  
                      });
                    } 
                  }
                  break;
      case "hc" : // item.centerH();
                  if (bleeding_area) {
                    if (item.type === "activeSelection") {
                      item.set({
                        left: bleeding_area?.left + bleeding_area?.width / 2 - item.getScaledWidth() / 2
                      });
                      item.forEachObject((object) => {
                        object.set({
                          left: (0 - object.getBoundingRect().width / 2),
                          originX: 'left'
                        });  
                      });
                    } else if (item?.metaData && item?.metaData?.type === 'text') {
                      item.set({
                        left: bleeding_area?.left + bleeding_area?.width / 2
                      });
                    } else {
                      item.set({
                        left: bleeding_area?.left + bleeding_area?.width / 2 - item.getScaledWidth() / 2
                      });
                    }
                  } else {
                    item.centerH();
                    if (item.type === "activeSelection") {
                      item.forEachObject((object) => {
                        object.set({
                          left: (0 - object.getBoundingRect().width / 2),
                          originX: 'left'
                        });  
                      });
                    }
                  }
                  break;
      case "hr" : if (bleeding_area) {
                    if (item.type === "activeSelection") {
                      item.set({
                        left: bleeding_area?.left + bleeding_area?.width - item.getScaledWidth()
                      });
                      let groupWidth = item.getScaledWidth();
                      item.forEachObject((object) => {
                        object.set({
                          left: (groupWidth / 2),
                          originX: 'right'
                        });  
                      });
                    } else if (item?.metaData && item?.metaData?.type === 'text') {
                      item.set({
                        left: bleeding_area?.left + bleeding_area?.width - item.getScaledWidth() / 2
                      });
                    } else {
                      item.set({
                        left: bleeding_area?.left + bleeding_area?.width - item.getScaledWidth()
                      });
                    }
                  } else {
                      item.set({
                        left: canvas.width - (item.getScaledWidth() / 2)
                      });
                      if (item.type === "activeSelection") {
                        let groupWidth = item.getScaledWidth();
                        item.forEachObject((object) => {
                          object.set({
                            left: (groupWidth / 2),
                            originX: 'right'
                          });  
                        });
                      }
                  }
                  break;
      case "vt" : if (bleeding_area) {
                    if (item.type === "activeSelection") {
                      item.set({
                        top: bleeding_area?.top
                      });
                      let groupHeight = item.getScaledHeight();
                      item.forEachObject((object) => {
                        object.set({
                          top: -(groupHeight / 2),
                          originY: 'top'
                        });  
                      });
                    } else if (item?.metaData && item?.metaData?.type === 'text') {
                      item.set({
                        top: bleeding_area?.top + (item.height / 2)
                      });
                    } else {
                      item.set({
                        top: bleeding_area?.top
                      });
                    }
                  } else {
                    item.set({
                      top: (item.height / 2)
                    });
                    if (item.type === "activeSelection") {
                      let groupHeight = item.getScaledHeight();
                      item.forEachObject((object) => {
                        object.set({
                          top: -(groupHeight / 2),
                          originY: 'top'
                        });  
                      });
                    } 
                  }
                  break;
      case "vc" : // item.centerV();
                  if (item.type === "activeSelection") {
                    item.set({
                      top: bleeding_area?.top + bleeding_area?.height / 2 - item.getScaledHeight() / 2
                    });
                    item.forEachObject((object) => {
                      object.set({
                        top: (0 - object.getBoundingRect().height / 2),
                        originY: 'top'
                      });  
                    });
                  } else if (bleeding_area) {
                      if (item?.metaData && item?.metaData?.type === 'text') {
                        item.set({
                          top: bleeding_area?.top + bleeding_area?.height / 2 
                        });
                      } else {
                        item.set({
                          top: bleeding_area?.top + bleeding_area?.height / 2 - item.getScaledHeight() / 2
                        });
                      }
                  } else {
                    item.centerV();
                    if (item.type === "activeSelection") {
                      item.forEachObject((object) => {
                        object.set({
                          top: (0 - object.getBoundingRect().height / 2),
                          originY: 'top'
                        });  
                      });
                    }
                  }
                  break;
      case "vb" : if (bleeding_area) {
                    if (item.type === "activeSelection") {
                      item.set({
                        top: bleeding_area?.top + bleeding_area?.height - item.getScaledHeight()
                      });
                      let groupHeight = item.getScaledHeight();
                      item.forEachObject((object) => {
                        object.set({
                          top: (groupHeight / 2),
                          originY: 'bottom'
                        });  
                      });
                    } else if (item?.metaData && item?.metaData?.type === 'text') {
                      item.set({
                        top: bleeding_area?.top + bleeding_area?.height - item.getScaledHeight() / 2 
                      });
                    } else {
                      item.set({
                        top: bleeding_area?.top + bleeding_area?.height - item.getScaledHeight()
                      });
                    }
                  } else {
                      item.set({
                        top: canvas.height - (item.getScaledHeight() / 2 )
                      });
                      if (item.type === "activeSelection") {
                        let groupHeight = item.getScaledHeight();
                        item.forEachObject((object) => {
                          object.set({
                            top: (groupHeight / 2),
                            originY: 'bottom'
                          });  
                        });
                      }
                  }
                  break;
      default :   item.center();
                  break;                       
    }
    item.setCoords();
    canvas.renderAll();
    save(canvas);
  }
}

export const addText = (id, str, canvas, canvasZoom, textType = "h", fontSize = 50 * (canvasZoom * 3), fontFamily = "Open Sans", fontWeight = "normal", fontStyle = "normal", fontCategory = null,  position = null) => {
  let mask = masks[canvas?.id];   
  // let fontSize = 50 * (canvasZoom * 3);
  fontSize = fontSize.toFixed(2);
  const text = (textType === "h") ? new fabric.Textbox(str, {
      metaData: { id: id, text: str, name: "object", type: "text", textType: textType, fontSize: Number(fontSize), fontFamily: fontFamily, fontWeight: fontWeight, fontStyle: fontStyle, textAlign: 'center', fontCategory: fontCategory, opacity: 1, lock: false },
      width: 350
  }) : new fabric.Text(str, {
    metaData: { id: id, text: str, name: "object", type: "text", textType: textType, fontSize: Number(fontSize), fontFamily: fontFamily, fontWeight: fontWeight, fontStyle: fontStyle, textAlign: 'center', fontCategory: fontCategory, opacity: 1, lock: false }
  })

  text.set({
    left: 0,
    top: 0,
    fontSize: Number(fontSize),
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontStyle: fontStyle,
    fill: position?.fill || "black",
    evented: true,
    clipPath: mask,   
    /* cornerSize: 9,
    transparentCorners: false,
    cornerColor: "white",
    cornerStrokeColor: "#29CC79",
    cornerStyle: "rect",                
    borderColor: "#29CC79", */
    textAlign: 'center',
    align: 'mid',
    originX: 'center', 
    originY: 'center',
    centeredScaling: true,
    lockScalingFlip: true,
    editable: true,
    stroke: null,
    strokeWidth: 0 
  });

  /* text.setControlVisible("ml", false);
  text.setControlVisible("mr", false);
  text.setControlVisible("mt", false);
  text.setControlVisible("mb", false); */

  text.set({
    left: mask?.left + text.width / 2,
    top: mask?.top + mask?.height / 2 - text.height / 2,
  });

  fabric.Textbox.prototype._wordJoiners = new RegExp("^[]");

  canvas.centerObject(text);
  
  if (position) {             
    text.set({
      width: position?.width,
      height: position?.height,
      left: mask.left + position.left,
      top: mask.top + position.top,
      scaleX: position?.scaleX,
      scaleY: position?.scaleY,
      angle: position?.angle,
      fontFamily: position?.fontFamily || text.fontFamily,
      fill: position?.fill,
      fontSize: position?.fontSize || text.fontSize,
    });
  }

  /* text.controls.deleteControl = new fabric.Control({
      x: 0,
      y: 0.5,
      offsetY: 12, 
      offsetX: 0,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon,
      cornerSize: 18,
  });

  var controlsUtils = fabric.controlsUtils;

  text.controls.mtr = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetY: -10,                                
      cursorStyleHandler: controlsUtils.rotationStyleHandler,
      actionHandler: controlsUtils.rotationWithSnapping,
      withConnection: false,
      cursorStyle: `url("/rotate.png"), auto`,
    actionName: "rotate",
    render: function (ctx, left, top) {
          ctx.save();
          ctx.fillStyle = "#29CC79";
      ctx.strokeStyle = "#29CC79";
          ctx.translate(left, top);
          ctx.beginPath();
          ctx.arc(0, 0, 5, 0, 2 * Math.PI);
          ctx.fill();
          ctx.stroke();
          ctx.restore();
    },
  });

  function deleteObject(eventData, transform) {
    removeObject(canvas);
    canvas.requestRenderAll();
  }

  function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  } */

  canvas.add(text);
  canvas.discardActiveObject().setActiveObject(text);
  canvas.renderAll();
  if (textType === "h") {
    let item = canvas.getActiveObject();
    const textLinesMaxWidth = item.textLines.reduce((max, _, i) => Math.max(max, item.getLineWidth(i)), 0);
    item.set({width: textLinesMaxWidth});
    canvas.renderAll();
  }
  save(canvas);
};

export const adjustTextWidth = (evt) => {
  if (evt.target instanceof fabric.IText) {
    const textLinesMaxWidth = evt.target.textLines.reduce((max, _, i) => Math.max(max, evt.target.getLineWidth(i)), 0);
    evt.target.set({width: textLinesMaxWidth});
  }
}

export const cloneItem = (item, obj) => {
  return new Promise((resolve, reject) => {
    item.clone(function (coopy) {
      coopy.set("top", coopy.top - obj?.top);
      coopy.set("left", coopy.left - obj?.left);
      coopy.set("clipPath", null);
      resolve(coopy);
    });
  });
};

export const togglePan = (pan, canvas) => {
  if (pan && canvas) {
    panMode = true;
    let objects = canvas.getObjects();
    let to_disabled_events = objects.filter(
      (x) => x?.metaData?.name === "object"
    );
    to_disabled_events.map((obj) => (obj.selectable = false));
    canvas.defaultCursor = "grab";
    canvas.discardActiveObject().renderAll();
  } else {
    panMode = false;
    if (canvas) {
      canvas.defaultCursor = null;
      canvas.selection = true;
      let objects = canvas.getObjects();
      let to_disabled_events = objects.filter(
        (x) => x?.metaData?.name === "object"
      );
      to_disabled_events.map((obj) => (obj.selectable = true));
    }
  }
};

export const toggleGrid = (canvas) => {
  let grid = canvas?.getObjects()?.find((x) => x.id === "grid");
  if (!grid) {
    let gridSize = 60;
    let separateLines = [];
    const wrapper = canvas?.getObjects()?.find((x) => x.id === "wrapper");
    for (let i = 0; i < wrapper.width / gridSize; i++) {
        let horizontalLine = new fabric.Line(
            [i * gridSize, 0, i * gridSize, wrapper.width], {
                stroke: 'gray'
            });
        let verticalLine = new fabric.Line(
            [0, i * gridSize, wrapper.width, i * gridSize], {
                stroke: 'gray'
            });
        separateLines.push(horizontalLine);
        separateLines.push(verticalLine);
    }
    
    // add grid lines to group
    let gridGroup = new fabric.Group([...separateLines], {
      id: 'grid',
      left: wrapper.left,
      top: wrapper.top,
      width: wrapper.width,
      height: wrapper.height,
      absolutePositioned: true,
      selectable: false,
      evented: false
    });

    /* let guideLinesH = [];
    let horizontalHandle = new fabric.Circle({
      left: -20,
      top: -20,
      radius: 20,
      fill: '#000',
      stroke: '#000',
      strokeWidth: 8,
      absolutePositioned: true,
    });
    let horizontalGuideline = new fabric.Line([0 , 0, wrapper.width, 2], {
      stroke: "#000",
      strokeWidth: 5
    });
    let guideLinesV = [];
    let verticalHandle = new fabric.Circle({
      left: -20,
      top: -20,
      radius: 20,
      fill: '#000',
      stroke: '#000',
      strokeWidth: 8,
      absolutePositioned: true,
    });
    let verticalGuideline = new fabric.Line([0 , 0, 2, wrapper.height], {
      stroke: "#000",
      strokeWidth: 5
    });
    guideLinesH.push(horizontalGuideline);
    guideLinesH.push(horizontalHandle);
    guideLinesV.push(verticalGuideline);
    guideLinesV.push(verticalHandle);
    let guidelineGroupH = new fabric.Group([...guideLinesH], {
      id: 'guideline_x',
      left: wrapper.left,
      top: wrapper.top + wrapper.height / 2,
      width: wrapper.width - 20,
      height: 2,
      selectable: true,
      evented: true,
      lockMovementX: true,
      absolutePositioned: true,
      isMoving: true,
      hasControls: false,
      hasBorders: false
    });
    
    guidelineGroupH.setControlsVisibility({
      mt: false, 
      mb: false, 
      ml: false, 
      mr: false, 
      bl: false,
      br: false, 
      tl: false, 
      tr: false,
      mtr: false,
      deleteControl: false 
    });
    
    let guidelineGroupV = new fabric.Group([...guideLinesV], {
      id: 'guideline_y',
      left: wrapper.left + wrapper.width / 2,
      top: wrapper.top,
      width: 2, 
      height: wrapper.height - 20,
      selectable: true,
      evented: true,
      lockMovementY: true,
      absolutePositioned: true,
      isMoving: true,
      hasControls: false,
      hasBorders: false
    });
    
    guidelineGroupV.setControlsVisibility({
      mt: false, 
      mb: false, 
      ml: false, 
      mr: false, 
      bl: false,
      br: false, 
      tl: false, 
      tr: false,
      mtr: false,
      deleteControl: false 
    }); */
    
    canvas.add(gridGroup);
  } else {
    const canvas_grid = canvas?.getObjects()?.find((x) => x.id === "grid");
    /* const guideline_x = canvas?.getObjects()?.find((x) => x.id === "guideline_x");
    const guideline_y = canvas?.getObjects()?.find((x) => x.id === "guideline_y"); */
    if (canvas_grid) {
      canvas.remove(canvas_grid);
    }
    /* if (guideline_x) {
      canvas.remove(guideline_x);
    }
    if (guideline_y) {
      canvas.remove(guideline_y);
    } */
  }
  canvas.discardActiveObject().renderAll();
};

export const save = (canvas) => {
  redo_front = [];
  redo_back = [];
  disableRedo_front = true;
  disableRedo_back = true;
  if (canvas && canvas?.id.toLowerCase() === 'front' && state_front) {
    undo_front.push(state_front);
    disableUndo_front = false;
  }
  if (canvas && canvas?.id.toLowerCase() === 'back' && state_back) {
    undo_back.push(state_back);
    disableUndo_back = false;
  }

  if (canvas && canvas?.id.toLowerCase() === 'front') {
    state_front = canvas.toJSON(['selectable','evented', 'cornerSize', 'metaData', 'textType', 'transparentCorners', 'cornerColor', 
          'cornerStrokeColor', 'cornerStyle', 'borderColor', 'align', 'centeredScaling', 'aCoords', 'lineCoords', 'oCoords', 
          'textLines', '_controlsVisibility', 'clipPath', 'path', 'pathSide', 'pathStartOffset', 'radius', 'pathAlign', 'id', 'crossOrigin']);
  }

  if (canvas && canvas?.id.toLowerCase() === 'back') {
    state_back = canvas.toJSON(['selectable','evented', 'cornerSize', 'metaData', 'textType', 'transparentCorners', 'cornerColor', 
          'cornerStrokeColor', 'cornerStyle', 'borderColor', 'align', 'centeredScaling', 'aCoords', 'lineCoords', 'oCoords', 
          'textLines', '_controlsVisibility', 'clipPath', 'path', 'pathSide', 'pathStartOffset', 'radius', 'pathAlign', 'id', 'crossOrigin']);
  }
}

export const replay = (action, canvas) => {
  canvas.discardActiveObject();
  canvas.renderAll();

  let mask = masks[canvas?.id];
  if (canvas && canvas?.id.toLowerCase() === 'front') {
    if (action === 'undo') {
      redo_front.push(state_front);
      state_front = undo_front.pop();
    } else {
      undo_front.push(state_front);
      state_front = redo_front.pop();
    }
    disableUndo_front = true;
    disableRedo_front = true;

    if (state_front?.objects && state_front?.objects.length > 0) {
      state_front?.objects.forEach((obj) => {
        if(obj.hasOwnProperty('clipPath')) {
          if (obj?.id === 'wrapper' || obj?.id === 'bleed_text1' || obj?.id === 'bleed_text2' || obj?.id === 'bleeding_area' || obj?.id === 'guides') {
            delete obj.clipPath;
          } else {
            obj.clipPath = mask;
          }
        }
      });
    }
    canvas.loadFromJSON(state_front, function() {
      canvas.getObjects().map((o) => {
        if (o?.id === 'bleed_text1' || o?.id === 'bleed_text2' || o?.id === 'wrapper' || o?.id === 'bleeding_area' || o?.id === 'guides') {
          delete o.clipPath;
        } else {
          o.set({'clipPath': mask});
        }
        return o;
      });
      canvas.renderAll();
      if (action === 'undo') {
        disableRedo_front = false;
        if (undo_front.length) {
          disableUndo_front = false;
        }
      } else {
        disableUndo_front = false;
        if (redo_front.length) {
          disableRedo_front = false;
        }
      }
    });
  }

  if (canvas && canvas?.id.toLowerCase() === 'back') {
    if (action === 'undo') {
      redo_back.push(state_back);
      state_back = undo_back.pop();
    } else {
      undo_back.push(state_back);
      state_back = redo_back.pop();
    }
    disableUndo_back = true;
    disableRedo_back = true;
    
    if (state_back?.objects && state_back?.objects.length > 0) {
      state_back?.objects.forEach((obj) => {
        if(obj.hasOwnProperty('clipPath')) {
          if (obj?.id === 'wrapper' || obj?.id === 'bleed_text1' || obj?.id === 'bleed_text2' || obj?.id === 'bleeding_area' || obj?.id === 'guides') {
            delete obj.clipPath;
          } else {
            obj.clipPath = mask;
          }
        }
      });
    }
    canvas.loadFromJSON(state_back, function() {
      canvas.getObjects().map((o) => {
        if (o?.id === 'bleed_text1' || o?.id === 'bleed_text2' || o?.id === 'wrapper' || o?.id === 'bleeding_area' || o?.id === 'guides') {
          delete o.clipPath;
        } else {
          o.set({'clipPath': mask});
        }
        return o;
      });
      canvas.renderAll();
      if (action === 'undo') {
        disableRedo_back = false;
        if (undo_back.length) {
          disableUndo_back = false;
        }
      } else {
        disableUndo_back = false;
        if (redo_back.length) {
          disableRedo_back = false;
        }
      }
    });
  }
}

export const undoAction = (canvas) => {
  if ((canvas?.id.toLowerCase() === 'front' && disableUndo_front) || (canvas?.id.toLowerCase() === 'back' && disableUndo_back)) {
    return;
  } else {
    replay('undo', canvas)
  }
}

export const redoAction = (canvas) => {
  if ((canvas?.id.toLowerCase() === 'front' && disableRedo_front) || (canvas?.id.toLowerCase() === 'back' && disableRedo_back)) {
    return;
  } else {
    replay('redo', canvas)
  }
}

export const drawing = (id, canvas) => {
  let mask = masks[canvas?.id];
  canvas.isDrawingMode = true;
  canvas.freeDrawingBrush.width = 5;
  canvas.freeDrawingBrush.color = '#000000';
  canvas.limitedToCanvasSize = true;
  canvas.freeDrawingBrush.clipPath = mask;
  canvas.on("path:created", (e) => {
    let path = e.path;
    path.set({
      type: "path",
      metaData: { id: id, name: "object", type: "path" },
      /* clipPath: mask,
      evented: true,
      cornerSize: 9,
      transparentCorners: false,
      cornerColor: "white",
      cornerStrokeColor: "#29CC79",
      cornerStyle: "rect",                
      borderColor: "#29CC79" */
    });

    /* path.setControlVisible("ml", false);
    path.setControlVisible("mr", false);
    path.setControlVisible("mt", false);
    path.setControlVisible("mb", false);

    path.controls.deleteControl = new fabric.Control({
      x: 0,
      y: 0.5,
      offsetY: 12, 
      offsetX: 0,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon,
      cornerSize: 18,
    });

    var controlsUtils = fabric.controlsUtils;

    path.controls.mtr = new fabric.Control({
      x: 0,
      y: -0.5,
      offsetY: -10,                                
      cursorStyleHandler: controlsUtils.rotationStyleHandler,
      actionHandler: controlsUtils.rotationWithSnapping,
      withConnection: false,
      cursorStyle: `url("/rotate.png"), auto`,
      actionName: "rotate",
      render: function (ctx, left, top) {
        ctx.save();
        ctx.fillStyle = "#29CC79";
        ctx.strokeStyle = "#29CC79";
        ctx.translate(left, top);
        ctx.beginPath();
        ctx.arc(0, 0, 5, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.restore();
      },
    });

    function deleteObject(eventData, transform) {
      removeObject(canvas);
      canvas.requestRenderAll();
    }

    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size);
      ctx.restore();
    } */
    addControls(canvas, path);
    canvas.renderAll();
    save(canvas);
  });
}

export const addLine = (id, canvas) => {
  drawLine = true;
  line_id = id;
}

export const addEllipse = (id, canvas) => {
  drawEllipse = true;
  ellipse_id = id;
}

export const addCircle = (id, canvas) => {
  drawCircle = true;
  circle_id = id;
}

export const addControls = (canvas, obj) => {
  let mask = masks[canvas?.id];
  obj.set({
    clipPath: mask,
    evented: true,
    cornerSize: 9,
    transparentCorners: false,
    cornerColor: "white",
    cornerStrokeColor: "#29CC79",
    cornerStyle: "rect",                
    borderColor: "#29CC79"
  });

  obj.setControlVisible("ml", false);
  obj.setControlVisible("mr", false);
  obj.setControlVisible("mt", false);
  obj.setControlVisible("mb", false);

  obj.controls.deleteControl = new fabric.Control({
    x: 0,
    y: 0.5,
    offsetY: 12, 
    offsetX: 0,
    cursorStyle: "pointer",
    mouseUpHandler: deleteObject,
    render: renderIcon,
    cornerSize: 18,
  });

  var controlsUtils = fabric.controlsUtils;

  obj.controls.mtr = new fabric.Control({
    x: 0,
    y: -0.5,
    offsetY: -10,                                
    cursorStyleHandler: controlsUtils.rotationStyleHandler,
    actionHandler: controlsUtils.rotationWithSnapping,
    withConnection: false,
    cursorStyle: `url("/rotate.png"), auto`,
    actionName: "rotate",
    render: function (ctx, left, top) {
      ctx.save();
      ctx.fillStyle = "#29CC79";
      ctx.strokeStyle = "#29CC79";
      ctx.translate(left, top);
      ctx.beginPath();
      ctx.arc(0, 0, 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
      ctx.restore();
    },
  });

  function deleteObject(eventData, transform) {
    removeObject(canvas);
    canvas.requestRenderAll();
  }

  function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

}

export const textTransform = (transform, canvas) => {
  let item = canvas.getActiveObject();
  if (item) {
    switch (transform) {
      case "t" :  item.text = item.text.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
                  break;
      case "c" :  item.text = fabric.util.string.capitalize(item.text, false); 
                  break;
      /* case "cm" : item.text = fabric.util.string.camelize(item.text); 
                  break; */            
      case "u" :  item.text = item.text.toUpperCase(); 
                  break;
      case "l" :  item.text = item.text.toLowerCase();
                  break;
      default  :  item.text = item.text.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
                  break;
    }
    canvas.renderAll();
    if (item?.metaData && item?.metaData?.type === "text" && (item?.metaData?.textType === "h" || item?.metaData?.textType === "v")) {
      const textLinesMaxWidth = item.textLines.reduce((max, _, i) => Math.max(max, item.getLineWidth(i)), 0);
      item.set({width: textLinesMaxWidth});
      canvas.renderAll();
    }
    save(canvas);
  }
}

export const selectAll = (canvas) => {
  const objectsList = canvas.getObjects()?.filter((x) => x?.metaData?.name === "object") || [];
  if (objectsList.length > 0) {
    canvas.discardActiveObject();
    let sel = new fabric.ActiveSelection(objectsList, {
      canvas: canvas
    });
    canvas.setActiveObject(sel);
    canvas.renderAll();
  }
}

export const addToGroup = (canvas) => {
  const item = canvas.getActiveObject();
  const items = canvas.getActiveObjects();
  if ((item && !item?.metaData) || (item && item?.metaData && item?.metaData?.type !== "group")) {
    let mask = masks[canvas?.id];
    canvas.discardActiveObject();
    const group = new fabric.Group(items, {
      metaData: { id: uuid(), name: "object", type: "group" }
    });
    
    group.set({
      evented: true,
      clipPath: mask,
      left: item.type === "activeSelection" ?  item?.left :  mask?.left,
      top: item.type === "activeSelection" ?  item?.top :  mask?.top,
    });

    items.forEach((object) => {
      canvas.remove(object);
    });
    
    canvas.add(group);
    canvas.setActiveObject(group);
    canvas.renderAll();
    save(canvas);
  }
}

export const clearPage = (canvas) => {
  const objectsList = canvas.getObjects()?.filter((x) => x?.metaData?.name === "object") || [];
  if (objectsList.length > 0) {
    canvas.discardActiveObject();
    objectsList.forEach((object) => {
      canvas.remove(object);
    });
  }
  let item = canvas?.getObjects()?.find((x) => x.id === "guides");
  if (item) {
    item.item(1).set({
      fill: "transparent"
    })
  }
  canvas.renderAll();
}

export const setBackground = (color, canvas) => {
  let item = canvas?.getObjects()?.find((x) => x.id === "guides");
  if (item) {
    item.item(1).set({
      fill: color
    })
    canvas.renderAll();
  }
}

export const loadDesign = async (canvas, design, setImgLoading) => {
  new Promise((resolve, reject) => {
    if (canvas.length > 0 && design.length > 0) {
      canvas.forEach((can, index) => {
        let mask = masks[can?.id];
        can.loadFromJSON(design[index]?.json_data, function() {
          can.getObjects().map((o) => {
            if (o.get('type') === 'image') {
              o.set({'crossOrigin': 'Anonymous'});
            }
            if (o.get("type") === "textbox") {
              o.set({width: 1000});
            }
            if (o?.id === 'bleed_text1' || o?.id === 'bleed_text2' || o?.id === 'wrapper' || o?.id === 'bleeding_area' || o?.id === 'guides') {
              delete o.clipPath;
            } else {
              o.set({'clipPath': mask});
            }
            return o;
          });
          can.renderAll();
          can.getObjects().map((o) => {
            if (o.get("type") === "textbox") {
              const textLinesMaxWidth = o.textLines.reduce((max, _, i) => Math.max(max, o.getLineWidth(i)), 0);
              o.set({width: textLinesMaxWidth});
            }
            return o;
          });
          can.renderAll();  
          setImgLoading(false);
        });
      });
      return resolve();
    } else {
      return reject();
    }
  })
}
