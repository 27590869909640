/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ShapeLineSelectAll1 = ({ color = "#14142B", className }) => {
  return (
    <svg
      className={`shape-line-select-all-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 19 20"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.19835 16.667C5.87232 17.638 4.99259 18.3337 3.9585 18.3337C2.64682 18.3337 1.5835 17.2144 1.5835 15.8337C1.5835 14.7452 2.24439 13.8191 3.16683 13.4759V6.52473C2.24439 6.18154 1.5835 5.25551 1.5835 4.16699C1.5835 2.78628 2.64682 1.66699 3.9585 1.66699C4.99259 1.66699 5.87232 2.36267 6.19835 3.33366H12.802C13.128 2.36267 14.0078 1.66699 15.0418 1.66699C16.3535 1.66699 17.4168 2.78628 17.4168 4.16699C17.4168 5.25551 16.7559 6.18154 15.8335 6.52473V13.4759C16.7559 13.8191 17.4168 14.7452 17.4168 15.8337C17.4168 17.2144 16.3535 18.3337 15.0418 18.3337C14.0078 18.3337 13.128 17.638 12.802 16.667H6.19835ZM6.19835 15.0003H12.802C13.0405 14.29 13.5753 13.727 14.2502 13.4759V6.52473C13.5753 6.27367 13.0405 5.71067 12.802 5.00033H6.19835C5.95984 5.71067 5.42499 6.27367 4.75016 6.52473V13.4759C5.42499 13.727 5.95984 14.29 6.19835 15.0003ZM3.9585 5.00033C4.39572 5.00033 4.75016 4.62723 4.75016 4.16699C4.75016 3.70676 4.39572 3.33366 3.9585 3.33366C3.52127 3.33366 3.16683 3.70676 3.16683 4.16699C3.16683 4.62723 3.52127 5.00033 3.9585 5.00033ZM15.0418 5.00033C15.4791 5.00033 15.8335 4.62723 15.8335 4.16699C15.8335 3.70676 15.4791 3.33366 15.0418 3.33366C14.6046 3.33366 14.2502 3.70676 14.2502 4.16699C14.2502 4.62723 14.6046 5.00033 15.0418 5.00033ZM15.0418 16.667C15.4791 16.667 15.8335 16.2939 15.8335 15.8337C15.8335 15.3734 15.4791 15.0003 15.0418 15.0003C14.6046 15.0003 14.2502 15.3734 14.2502 15.8337C14.2502 16.2939 14.6046 16.667 15.0418 16.667ZM3.9585 16.667C4.39572 16.667 4.75016 16.2939 4.75016 15.8337C4.75016 15.3734 4.39572 15.0003 3.9585 15.0003C3.52127 15.0003 3.16683 15.3734 3.16683 15.8337C3.16683 16.2939 3.52127 16.667 3.9585 16.667Z"
        fill={color}
      />
    </svg>
  );
};

ShapeLineSelectAll1.propTypes = {
  color: PropTypes.string,
};
