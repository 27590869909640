import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productDetail(state, action) {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { productDetail } = productSlice.actions;

export default productSlice.reducer;
