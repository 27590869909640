import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const backgroundCategoriesSlice = createSlice({
  name: "backgroundCatetories",
  initialState,
  reducers: {
    backgroundCategoriesList(state, action) {
        state.push(...action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { backgroundCategoriesList } = backgroundCategoriesSlice.actions;

export default backgroundCategoriesSlice.reducer;
