import {createSlice} from "@reduxjs/toolkit"

const initialState = []

const shapesSlice = createSlice({
    name: "shapes",
    initialState,
    reducers: {
        shapesList(state, action){
            return action.payload
        }
    }
})

export const {shapesList} = shapesSlice.actions
export default shapesSlice.reducer