/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const FileCopyLine = ({ className }) => {
  return (
    <svg
      className={`file-copy-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g">
        <path
          className="path"
          d="M5.83317 5.50033H6.33317V5.00033V2.50033C6.33317 2.31623 6.48241 2.16699 6.6665 2.16699H16.6665C16.8506 2.16699 16.9998 2.31623 16.9998 2.50033V14.167C16.9998 14.3511 16.8506 14.5003 16.6665 14.5003H14.1665H13.6665V15.0003V17.4996C13.6665 17.6816 13.5179 17.8337 13.3275 17.8337H3.33888C3.14784 17.8337 3.00005 17.6833 3 17.4997C3 17.4996 3 17.4996 3 17.4996L3.00217 5.83448C3.0022 5.65238 3.15087 5.50033 3.34118 5.50033H5.83317ZM4.16868 6.16699H3.66878L3.66868 6.6669L3.66682 16.6669L3.66673 17.167H4.16682H12.4998H12.9998V16.667V6.66699V6.16699H12.4998H4.16868ZM6.99983 5.00033V5.50033H7.49983H13.6665V13.3337V13.8337H14.1665H15.8332H16.3332V13.3337V3.33366V2.83366H15.8332H7.49983H6.99983V3.33366V5.00033Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
      </g>
    </svg>
  );
};
