/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ArrowGoForwardLineRedo = ({ className }) => {
  return (
    <svg
      className={`arrow-go-forward-line-redo ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g">
        <path
          className="path"
          d="M15.143 5.83312H9.16667C6.40524 5.83312 4.16667 8.0717 4.16667 10.8332C4.16667 13.5946 6.40524 15.8332 9.16667 15.8332H16.6667V17.4998H9.16667C5.48477 17.4998 2.5 14.515 2.5 10.8332C2.5 7.15123 5.48477 4.16646 9.16667 4.16646H15.143L13.03 2.05352L14.2086 0.875L18.3333 4.99979L14.2086 9.12458L13.03 7.94608L15.143 5.83312Z"
          fill="#FCFCFC"
        />
      </g>
    </svg>
  );
};
