import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { fabric } from "fabric";
import { useSelector } from "react-redux";
import { AlignGuidelines } from "fabric-guideline-plugin";
import { Buffer } from "buffer";
// import { StudioContext } from "../../contexts";
import {
  initializeCanvas,
  updateWrapper,
  addToCanvas,
  addText,
  cloneItem,
  sortElements,
  updateElement,
  deleteElement,
  setActiveElement,
  togglePan,
  lockUnlockElement,
  rotateElement,
  cutElement,
  copyElement,
  pasteElement,
  alignElement,
  toggleGrid,
  save,
  undoAction,
  redoAction,
  // drawing,
  // addLine,
  // addEllipse,
  // addCircle,
  textTransform,
  selectAll,
  addToGroup,
  clearPage,
  setBackground,
  loadDesign,
} from "./utils";
import { Header } from "../Header/Header";
import { Sidebar } from "../Sidebar/Sidebar";
import { Topbar } from "../Topbar/Topbar";
import { Footer } from "../Footer/Footer";
import { EyeLine1 } from "../../icons/EyeLine1";
import { ZoomInLine } from "../../icons/ZoomInLine";
import { ZoomOutLine } from "../../icons/ZoomOutLine";
import { ArrowLeftSLine1 } from "../../icons/ArrowLeftSLine1";
import { ArrowRightSLine1 } from "../../icons/ArrowRightSLine1";
import AlertComponent from "../AlertComponent/AlertComponent";
import "./DesignTool.css";

const DesignTool = () => {
  const containerRef = useRef();
  const canvasRef = useRef([]);
  const contextMenuRef = useRef(null);
  const pageTabRef = useRef([]);
  const [canvas, setCanvas] = useState([]);
  // const [isLoaded, setLoaded] = useState(false);
  const [preloaded, setPreloaded] = useState(false);
  const [canvasZoom, setCanvasZoom] = useState(0.35);
  const [elements, setElements] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);
  const [pan, setPan] = useState(false);
  const [selectedCanvas, setSelectedCanvas] = useState(0);
  const [collapse, setCollapse] = useState(false);
  const [design, setDesign] = useState(false);
  const [alert, setAlert] = useState(false);
  const [title, setTitle] = useState();
  const [errorMessage, setErrorMessage] = useState();

  // const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const overlays = useMemo(() => product?.screen_areas, [product]);
  const variant = useSelector((state) => state.variant);
  const state = useSelector((state) => state.state);
  const { slug } = useParams();
  let product_width;
  let product_height;
  let slug_array;

  if (slug?.length > 0) {
    if (slug.includes("&")) {
      slug_array = slug.split("&");
      slug_array.forEach((arr) => {
        if (arr.includes("dimensions=")) {
          arr = arr.replace("dimensions=", "");
          const [width, height] = arr.split("x");
          product_width = width;
          product_height = height;
        }
      });
    }
  }
  /* console.log("product=>", product);
  console.log("variant=>", variant);
  console.log("state=>", state); */
  /* const canvasPages = React.useMemo(
    () => [
      {
        location: "front",
        width: 600,
        height: 600,
        // backgroundColor: "pink",
        link: "/assets/front.jpeg",
      },
      {
        location: "back",
        width: 600,
        height: 600,
        // backgroundColor: "yellow",
        link: "/assets/back.jpeg",
      },
    ],
    []
  ); */

  /* const state = {
    printing_method: "DTF",
  }; */

  /* const product = useMemo(
    () => [
      {
        print_areas: {
          neck: "76.2 x 76.2",
          width: 355.6,
          format: "png",
          height: 406.4,
          sleeve: "76.2 x 76.2",
          front_back: "355.6 x 406.4",
          offset_top: 2,
        },
        screen_areas: [
          {
            data: {
              link: "/assets/front.jpeg",
              name: "front.jpg",
            },
            type: "front",
            areas: [
              {
                values: {
                  top: "25.66%",
                  left: "30.78%",
                  scale: 0.875,
                  width: "39.34%",
                  height: "44.84%",
                  rotatation: "0deg",
                },
                position: {
                  x: 72.0252,
                  y: 60.0444,
                  scale: 0.875,
                  width: 92.0556,
                  height: 104.9256,
                },
              },
            ],
          },
          {
            data: {
              link: "/assets/back.jpeg",
              name: "back.jpg",
            },
            type: "back",
            areas: [
              {
                values: {
                  top: "19.51%",
                  left: "31.22%",
                  scale: 0.875,
                  width: "39.12%",
                  height: "44.62%",
                  rotatation: "0deg",
                },
                position: {
                  x: 73.0548,
                  y: 45.653400000000005,
                  scale: 0.875,
                  width: 91.5408,
                  height: 104.4108,
                },
              },
            ],
          },
        ],
      },
    ],
    []
  ); */

  /* const {
      variant,
      product,
      setLayers,
      isDraft,
      layers,
      setDraft,
      state,
      step,
      setStep,
      setSelectedVariants,
      setPrices,
      generating,
      setGenerating,
    } = React.useContext(StudioContext); */

  // const overlays = useMemo(() => product[0]?.screen_areas, [product]);

  // const overlays = product?.product?.screen_areas;

  /* useEffect(() => {
      setCanvas(initCanvas());
    }, []); */

  /* const initCanvas = () => (
          new fabric.Canvas('canvas', {
            width: 500,
            height: 500,
            backgroundColor: 'pink',
            preserveObjectStacking: true,
            selection: true,
          })
    )  */

  const initCanvas = useCallback(
    (lay, index) =>
      new fabric.Canvas(canvasRef.current[index], {
        width:
          product_width?.length > 0
            ? Number(product_width)
            : containerRef.current?.clientWidth,
        height:
          product_height?.length > 0
            ? Number(product_height)
            : containerRef.current?.clientHeight,
        preserveObjectStacking: true,
        selection: true,
        id: lay?.location,
        image: lay?.link,
        stopContextMenu: true,
        fireRightClick: true,
      }),
    [product_width, product_height]
  );

  useEffect(() => {
    if (variant?.images?.length > 0 && !preloaded) {
      let imgs = variant?.images;
      let images = [...imgs];
      imgs = images.sort((a, b) => {
        if (a.location === "front") return -1;
        if (b.location === "front") return 1;
        return 0;
      });
      let canvases = [];
      imgs?.map((img, c) => canvases.push(initCanvas(img, c)));
      setCanvas(canvases);
      setPreloaded(true);
      if (canvases.length > 0) {
        for (let i = 0; i < canvases.length; i++) {
          const guideline = new AlignGuidelines({
            canvas: canvases[i],
            /* aligningOptions: {
                      lineColor: "#FF0000",
                      lineWidth: 2,
                      lineMargin: 2,
                    } */
          });
          guideline.init();
        }
      }
    }
  }, [variant, initCanvas, preloaded]);

  /* useEffect(() => {
    if (canvasPages?.length > 0 && !preloaded) {
      let canvases = [];
      canvasPages?.map((img, c) => canvases.push(initCanvas(img, c)));
      setCanvas(canvases);
      setPreloaded(true);
      if (canvases.length > 0) {
        for (let i = 0; i < canvases.length; i++) {
          const guideline = new AlignGuidelines({
            canvas: canvases[i]
          });
          guideline.init();
        }
      }
    }
  }, [canvasPages, initCanvas, preloaded]); */

  /* const getScreenAreas = useCallback(
    (type) => {
      let all_overlays = [];
      if (state?.printing_method === "EMBROIDERY") {
        all_overlays =
          overlays
            ?.find((x) => x?.type === type)
            ?.areas?.filter((y) => y.emb) || null;
      } else {
        all_overlays =
          overlays
            ?.find((x) => x?.type === type)
            ?.areas?.filter((y) => !y.emb) || null;
      }
      return all_overlays;
    },
    [overlays, state?.printing_method]
  ); */

  const getScreenAreas = React.useCallback(
    (type) => {
      let all_overlays = [];
      if (state?.printing_method === "EMBROIDERY") {
        all_overlays =
          overlays
            ?.find((x) => x?.type === type)
            ?.areas?.filter((y) => y.emb) || null;
      } else {
        all_overlays =
          overlays
            ?.find((x) => x?.type === type)
            ?.areas?.filter((y) => !y.emb) || null;
      }
      return all_overlays;
    },
    [overlays, state?.printing_method]
  );

  useEffect(() => {
    if (canvas?.length > 0) {
      canvas?.map((can, c) =>
        initializeCanvas({
          canvas: can,
          wrapper: can?.image,
          areas: getScreenAreas(can?.id),
          onZoom: setCanvasZoom,
          onElementsChange: setElements,
          setImgLoading,
        })
          .then((canv) => {
            /* if (c === canvas?.length - 1) {
              setLoaded(true);
            } */
            save(canvas[c]);
          })
          .catch((err) => {
            console.log("Error", err);
          })
      );
    }
  }, [canvas, getScreenAreas]);

  /* useEffect(() => {
    if (canvas?.length > 0) {
      let imgs = [];
      imgs = imgs.sort((a, b) => {
        if (a.location === "front") return -1;
        if (b.location === "front") return 1;
        return 0;
      });
      canvas?.map((can, c) => updateWrapper(can, imgs[c], setImgLoading));
    }
  }, [canvas]); */

  useEffect(() => {
    if (canvas?.length > 0) {
      let imgs = variant?.images || [];
      let images = [...imgs];
      imgs = images?.sort((a, b) => {
        if (a.location === "front") return -1;
        if (b.location === "front") return 1;
        return 0;
      });
      canvas?.map((can, c) => updateWrapper(can, imgs[c], setImgLoading));
    }
  }, [variant, canvas]);

  useEffect(() => {
    if (canvas && selectedCanvas > -1) {
      togglePan(pan, canvas[selectedCanvas]);
    }
  }, [pan, canvas, selectedCanvas]);

  const zoomIn = () => {
    let zoom = canvas[selectedCanvas].getZoom();
    zoom += 0.1;
    if (zoom > 1.5) zoom = 1.5;
    canvas[selectedCanvas].zoomToPoint(
      new fabric.Point(
        canvas[selectedCanvas].width / 2,
        canvas[selectedCanvas].height / 2
      ),
      zoom
    );
  };

  const zoomOut = () => {
    let zoom = canvas[selectedCanvas].getZoom();
    zoom -= 0.1;
    if (zoom < 0.1) zoom = 0.1;
    canvas[selectedCanvas].zoomToPoint(
      new fabric.Point(
        canvas[selectedCanvas].width / 2,
        canvas[selectedCanvas].height / 2
      ),
      zoom
    );
  };

  const imageUpload = async (base64Array) => {
    const imagesList = base64Array?.map((base64) => {
      return new Promise((resolve, reject) => {
        const AWS = require("aws-sdk");
        const Bluebird = require("bluebird");
        const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
        const AWS_SECRET_ACCESS_KEY =
          process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
        const AWS_DEFAULT_REGION = process.env.REACT_APP_AWS_DEFAULT_REGION;
        const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;
        const REACT_APP_AWS_ENDPOINT = process.env.REACT_APP_AWS_ENDPOINT;

        AWS.config.setPromisesDependency(Bluebird);
        AWS.config.update({
          accessKeyId: AWS_ACCESS_KEY_ID,
          secretAccessKey: AWS_SECRET_ACCESS_KEY,
          region: AWS_DEFAULT_REGION,
          useAccelerateEndpoint: true,
        });

        const s3 = new AWS.S3({
          params: { Bucket: AWS_BUCKET, Accelerate: true },
        });
        const base64Data = new Buffer.from(
          base64.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        const type = base64.split(";")[0].split("/")[1];
        const now = Date.now();
        let timestamp = now;

        const params = {
          Key: `pixflo/user-uploads/images/design_${timestamp}.${type}`,
          Body: base64Data,
          ContentEncoding: "base64",
          ContentType: type === "svg" ? `image/svg+xml` : `image/${type}`,
        };

        s3.putObject(params, function (err, data) {
          if (err) {
            return reject;
          } else {
            resolve(
              REACT_APP_AWS_ENDPOINT +
                `/pixflo/user-uploads/images/design_${timestamp}.${type}`
            );
          }
        });
      });
    });

    const imagesArray = await Promise.all(imagesList).then(async (images) => {
      return images;
    });
    return imagesArray;
  };

  const saveDesign = useCallback(async (canvases, ind) => {
    let all_prints = [];
    let data_array = [];
    const design_data = canvases?.map((can, index) => {
      return new Promise(async (resolve, reject) => {
        let objs = can.getObjects();
        let bleed_layer = objs?.find((x) => x.id === "bleeding_area");
        let immmg = objs?.filter((x) => x?.metaData?.name === "object");
        if (bleed_layer) {
          const printCanvas = new fabric.StaticCanvas(null, {
            preserveObjectStacking: true,
          });
          printCanvas.width = bleed_layer.width * 1.5;
          printCanvas.height = bleed_layer.height * 1.5;
          if (immmg?.length > 0) {
            let overlay_items = [];
            immmg?.map((immg) => {
              return overlay_items.push(cloneItem(immg, bleed_layer));
            });
            await Promise.all(overlay_items).then(async (results) => {
              results.map((res) => printCanvas.add(res));
              printCanvas.setZoom(1.5);
              let exportSVG = printCanvas.toSVG().split("<svg");
              exportSVG = "<svg" + exportSVG[1];
              const printData = printCanvas.toDataURL({
                format: "png",
                multiplier: 2,
              });
              const mockupData = can.toDataURL({
                format: "png",
                multiplier: 1,
              });
              const mockupJsonData = can.toJSON([
                "selectable",
                "evented",
                "cornerSize",
                "metaData",
                "textType",
                "transparentCorners",
                "cornerColor",
                "cornerStrokeColor",
                "cornerStyle",
                "borderColor",
                "align",
                "centeredScaling",
                "aCoords",
                "lineCoords",
                "oCoords",
                "textLines",
                "_controlsVisibility",
                "clipPath",
                "path",
                "pathSide",
                "pathStartOffset",
                "radius",
                "pathAlign",
                "id",
              ]);

              all_prints.push({
                id: can.id,
                index: index,
                svg_data: exportSVG,
                json_data: mockupJsonData,
              });
              data_array.push(printData);
              data_array.push(mockupData);
            });
          } else {
            printCanvas.setZoom(1.5);
            let exportSVG = printCanvas.toSVG().split("<svg");
            exportSVG = "<svg" + exportSVG[1];
            const printData = printCanvas.toDataURL({
              format: "png",
              multiplier: 2,
            });
            const mockupData = can.toDataURL({
              format: "png",
              multiplier: 1,
            });
            const mockupJsonData = can.toJSON([
              "selectable",
              "evented",
              "cornerSize",
              "metaData",
              "textType",
              "transparentCorners",
              "cornerColor",
              "cornerStrokeColor",
              "cornerStyle",
              "borderColor",
              "align",
              "centeredScaling",
              "aCoords",
              "lineCoords",
              "oCoords",
              "textLines",
              "_controlsVisibility",
              "clipPath",
              "path",
              "pathSide",
              "pathStartOffset",
              "radius",
              "pathAlign",
              "id",
            ]);

            all_prints.push({
              id: can.id,
              index: index,
              svg_data: exportSVG,
              json_data: mockupJsonData,
            });
            data_array.push(printData);
            data_array.push(mockupData);
          }
          resolve(data_array);
        }
      });
    });

    await Promise.all(design_data).then(async (data) => {
      let aws_data = await imageUpload(data[0]);
      if (aws_data.length > 0) {
        all_prints?.forEach((data) => {
          let [image, mockup_image] = aws_data;
          data.image = image;
          data.mockup_image = mockup_image;
          aws_data.splice(0, 2);
        });
      }
      return all_prints;
    });

    return all_prints;
  }, []);

  const onKeyDownHandler = useCallback(
    (event) => {
      let key;
      if (window.event) {
        key = window.event.keyCode;
      } else {
      }

      switch (key) {
        case 67: // Ctrl+C or Cmd+C
          if (
            (event.ctrlKey && navigator.appVersion.indexOf("Mac") === -1) ||
            (!event.ctrlKey && navigator.appVersion.indexOf("Mac") !== -1)
          ) {
            event.preventDefault();
            copyElement(canvas[selectedCanvas]);
          }
          break;
        case 86: // Ctrl+V or Cmd+V
          if (
            (event.ctrlKey && navigator.appVersion.indexOf("Mac") === -1) ||
            (!event.ctrlKey && navigator.appVersion.indexOf("Mac") !== -1)
          ) {
            event.preventDefault();
            pasteElement(uuid(), canvas[selectedCanvas]);
          }
          break;

        /* case 8: // Ctrl+Delete or Cmd+Delete
          if (
            (event.ctrlKey && navigator.appVersion.indexOf("Mac") === -1) ||
            (!event.ctrlKey && navigator.appVersion.indexOf("Mac") !== -1)
          ) {
            event.preventDefault();
            deleteElement(
              canvas[selectedCanvas]?.getActiveObject()?.metaData?.id,
              canvas[selectedCanvas]
            );
          }
          break; */

        default:
          // TODO
          break;
      }
    },
    [canvas, selectedCanvas]
  );

  const createListenersKeyboard = useCallback(() => {
    document.onkeydown = onKeyDownHandler;
    //document.onkeyup = onKeyUpHandler;
  }, [onKeyDownHandler]);

  useEffect(() => {
    createListenersKeyboard();
  }, [createListenersKeyboard]);

  useEffect(() => {
    if (canvas && canvas?.length > 0) {
      canvas[selectedCanvas].on("mouse:up", (event) => {
        if (event.button === 3) {
          contextMenuRef.current.style.display = "block";
          contextMenuRef.current.style.left = `${event.e.clientX - 408}px`;
          contextMenuRef.current.style.top = `${event.e.offsetY + 50}px`;
        } else {
          contextMenuRef.current.style.display = "none";
        }
      });
      // console.log(pageTabRef)
    }
    
  }, [canvas, selectedCanvas]);


  const sendToFront = () => {
    const fromIndex = elements.findIndex((elem) => elem.selected === true);
    const toIndex = 0;
    const activeObject = canvas[selectedCanvas].getActiveObject();
    if (fromIndex !== toIndex) {
      const allLayers = [...elements];
      const item = allLayers.splice(fromIndex, 1)[0];
      allLayers.splice(toIndex, 0, item);
      sortElements(allLayers, canvas[selectedCanvas]);
      if (activeObject) {
        canvas[selectedCanvas]
          .discardActiveObject()
          .setActiveObject(activeObject);
      }
      canvas[selectedCanvas].renderAll();
    }
  };

  const sendToBack = () => {
    const fromIndex = elements.findIndex((elem) => elem.selected === true);
    const toIndex = elements.length - 1;
    const activeObject = canvas[selectedCanvas].getActiveObject();
    if (fromIndex !== toIndex) {
      const allLayers = [...elements];
      const item = allLayers.splice(fromIndex, 1)[0];
      allLayers.splice(toIndex, 0, item);
      sortElements(allLayers, canvas[selectedCanvas]);
      if (activeObject) {
        canvas[selectedCanvas]
          .discardActiveObject()
          .setActiveObject(activeObject);
      }
      canvas[selectedCanvas].renderAll();
    }
  };

  const updateVariant = (variant) => {
    setSelectedVariant(variant);
  };

  return (
    <>
      <div className="main-container">
        <Header
          canvas={canvas}
          saveDesign={saveDesign}
          setDesign={setDesign}
          setAlert={setAlert}
          setTitle={setTitle}
          setErrorMessage={setErrorMessage}
        />
        <div className="setions-wrapper">
          <div className="left-section">
            <Sidebar
              addItem={(img, type) =>
                addToCanvas(
                  uuid(),
                  img,
                  type,
                  canvas[selectedCanvas],
                  setImgLoading
                )
              }
              addText={(
                str,
                textType,
                fontSize,
                fontFamily,
                fontWeight,
                fontStyle,
                fontCategory
              ) =>
                addText(
                  uuid(),
                  str,
                  canvas[selectedCanvas],
                  canvasZoom,
                  textType,
                  fontSize,
                  fontFamily,
                  fontWeight,
                  fontStyle,
                  fontCategory
                )
              }
              setElements={(items) => {
                sortElements(items, canvas[selectedCanvas]);
              }}
              items={elements}
              onUpdate={(data) => updateElement(data, canvas[selectedCanvas])}
              onDelete={(id) => deleteElement(id, canvas[selectedCanvas])}
              setActive={(id, canvas) => setActiveElement(id, canvas)}
              rotateElement={(angle) =>
                rotateElement(angle, canvas[selectedCanvas])
              }
              canvasZoom={canvasZoom}
              alignElement={(align) =>
                alignElement(align, canvas[selectedCanvas])
              }
              canvas={canvas[selectedCanvas]}
              textTransform={(transform) =>
                textTransform(transform, canvas[selectedCanvas])
              }
              collapse={collapse}
              setCollapse={(data) => setCollapse(data)}
              setBackground={(color) =>
                setBackground(color, canvas[selectedCanvas])
              }
              canvases={canvas}
              loadDesign={(canvas, design) =>
                loadDesign(canvas, design, setImgLoading)
              }
              setImgLoading={setImgLoading}
              design={design}
              setDesign={setDesign}
              imageUpload={imageUpload}
              sendToFront={sendToFront}
              sendToBack={sendToBack}
              pageTabRef={pageTabRef}
              setAlert={setAlert}
              setTitle={setTitle}
              setErrorMessage={setErrorMessage}
              updateVariant={updateVariant}
              deleteElement={deleteElement}
            />
            <div
              className="collapse-wrapper cursor-pointer"
              onClick={() => {
                setCollapse((prev) => !prev);
              }}
              style={collapse === true ? { left: "79px" } : { left: "396px" }}
            >
              <div className="left-div-8">
                {collapse === false && (
                  <ArrowLeftSLine1
                    className="arrow-down-s-line-3"
                    color="#FCFCFC"
                  />
                )}
                {collapse === true && (
                  <ArrowRightSLine1
                    className="arrow-down-s-line-3"
                    color="#FCFCFC"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="right-section">
            <Topbar
              cutElement={() => cutElement(canvas[selectedCanvas])}
              copyElement={() => copyElement(canvas[selectedCanvas])}
              pasteElement={() => pasteElement(uuid(), canvas[selectedCanvas])}
              alignElement={(align) =>
                alignElement(align, canvas[selectedCanvas])
              }
              selectAll={() => selectAll(canvas[selectedCanvas])}
              element={elements.filter((elem) => elem.selected)[0]}
              onUpdate={(data) => updateElement(data, canvas[selectedCanvas])}
              onLockUnlock={(data) =>
                lockUnlockElement(data, canvas[selectedCanvas])
              }
              canvas={canvas[selectedCanvas]}
              rotateElement={(angle) =>
                rotateElement(angle, canvas[selectedCanvas])
              }
              toggleGrid={() => toggleGrid(canvas[selectedCanvas])}
              addToGroup={() => addToGroup(canvas[selectedCanvas])}
              clearPage={() => clearPage(canvas[selectedCanvas])}
              undoAction={() => undoAction(canvas[selectedCanvas])}
              redoAction={() => redoAction(canvas[selectedCanvas])}
            />
            <div className="designtool-container" ref={containerRef}>
              {overlays?.length > 1 && (
                <div className="pages-container">
                  {overlays?.map((la, l) => (
                    <div
                      className="page"
                      key={l}
                      style={{
                        opacity: selectedCanvas === l ? "1" : "0.5",
                        /* boxShadow:
                      selectedCanvas === l
                        ? "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)"
                        : "none", */
                        /* background:
                        selectedCanvas === l
                          ? "linear-gradient(180deg, rgb(63, 165, 60) 0%, rgb(0, 108.29, 208.25) 100%)"
                          : "unset", */
                        borderImage:
                          selectedCanvas === l
                            ? "linear-gradient(rgb(63, 165, 60), rgb(0, 108.29, 208.25)) 1"
                            : "unset",
                        borderColor:
                          selectedCanvas === l ? "transparent" : "unset",
                        borderWidth: selectedCanvas === l ? "2px" : "unset",
                        borderStyle: selectedCanvas === l ? "solid" : "unset",
                      }}
                      onClick={() => {
                        canvas[selectedCanvas]
                          .discardActiveObject()
                          .renderAll();
                        canvas[selectedCanvas].fire("mouse:down");
                        setSelectedCanvas(l);
                        canvas[l].fire("selection:updated");
                      }}
                      // ref={(elem) => (pageTabRef.current[la.location] = elem)}
                      ref={(elem) => (pageTabRef.current[la.type] = elem)}
                    >
                      {canvas?.length > 1 && (
                        <img
                          className="page-bg"
                          src={
                            selectedVariant?.images?.find(
                              (variant) => variant?.location === la.type
                            )?.link
                              ? variant?.images?.find(
                                  (variant) => variant?.location === la.type
                                )?.link
                              : la?.data?.link
                          }
                          alt={la.location}
                        />
                      )}
                      {/* <span className="page-label">{la.location}</span> */}
                    </div>
                  ))}
                </div>
              )}
              {overlays?.length > 0 &&
                overlays?.map((overlay, o) => (
                  <div
                    key={o}
                    className="designtool-wrapper"
                    style={{ display: selectedCanvas === o ? "block" : "none" }}
                  >
                    <canvas ref={(el) => (canvasRef.current[o] = el)} />
                  </div>
                ))}
              <ul
                ref={contextMenuRef}
                className="context-menu"
                style={{ display: "none" }}
              >
                <li
                  onClick={() => {
                    undoAction(canvas[selectedCanvas]);
                    contextMenuRef.current.style.display = "none";
                  }}
                >
                  Undo
                </li>
                <li
                  onClick={() => {
                    redoAction(canvas[selectedCanvas]);
                    contextMenuRef.current.style.display = "none";
                  }}
                >
                  Redo
                </li>
                <li
                  onClick={() => {
                    copyElement(canvas[selectedCanvas]);
                    contextMenuRef.current.style.display = "none";
                  }}
                >
                  Copy<span className="shortcut">CTRL+C</span>
                </li>
                <li
                  onClick={() => {
                    pasteElement(uuid(), canvas[selectedCanvas]);
                    contextMenuRef.current.style.display = "none";
                  }}
                >
                  Paste<span className="shortcut">CTRL+V</span>
                </li>
                <li
                  onClick={() => {
                    deleteElement(
                      canvas[selectedCanvas]?.getActiveObject()?.metaData?.id,
                      canvas[selectedCanvas]
                    );
                    contextMenuRef.current.style.display = "none";
                  }}
                  className="separator"
                >
                  Delete
                </li>
                <li
                  onClick={() => {
                    addToGroup(canvas[selectedCanvas]);
                    contextMenuRef.current.style.display = "none";
                  }}
                  className="separator"
                >
                  Group
                </li>
                {/* <li>Ungroup</li> */}
                <li
                  onClick={() => {
                    sendToFront();
                    contextMenuRef.current.style.display = "none";
                  }}
                  className="separator"
                >
                  Bring to Front
                </li>
                <li
                  onClick={() => {
                    sendToBack();
                    contextMenuRef.current.style.display = "none";
                  }}
                >
                  Send to Back
                </li>
              </ul>
            </div>
            <div className="cut-safeareas-12">
              <div className="item-89">
                <EyeLine1 className="eye-line-10" color="#CA024F" />
                <div className="text-wrapper-192">Cut Lines</div>
              </div>
              <div className="item-90">
                <EyeLine1 className="eye-line-11" color="#008A00" />
                <div className="text-wrapper-193">Safe Zones</div>
              </div>
            </div>
            <div className={`drag-wrapper ${pan ? "active" : ""}`}>
              {pan && (
                <img
                  onClick={() => setPan((value) => !value)}
                  className="hand cursor-pointer"
                  src="/img/hand-active.svg"
                  alt="Drag"
                />
              )}
              {!pan && (
                <img
                  onClick={() => setPan((value) => !value)}
                  className="hand cursor-pointer"
                  src="/img/hand.svg"
                  alt="Drag"
                />
              )}
            </div>
            <div className="zoom-12">
              <div onClick={() => zoomIn()} className="left-div-23">
                <ZoomInLine className="icon-instance-node-13 cursor-pointer" />
              </div>
              <div onClick={() => zoomOut()} className="right-div">
                <ZoomOutLine className="icon-instance-node-13 cursor-pointer" />
              </div>
            </div>
            <Footer />
            {imgLoading && (
              <img className="loader" src="/img/loader.gif" alt="loader" />
            )}
          </div>
        </div>
        {alert && (
          <AlertComponent
            alert={alert}
            title={title}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </>
  );
};

export default DesignTool;
