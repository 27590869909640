import { createSlice } from "@reduxjs/toolkit";

const initialState = []

const iconsSlice = createSlice({
    name: "icons",
    initialState,
    reducers: {
        iconsList(state, action) {
            if (Array.isArray(action.payload)) {
                state.push(...action.payload)
                // return [...state, ...action.payload]
            } else {
                console.error('Payload is not an array:', action.payload);
            }
        }
    }
})

export const {iconsList} = iconsSlice.actions
export default iconsSlice.reducer