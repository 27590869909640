/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const RiCloseCircleLine = ({ className, color="#fcfcfc" }) => {
  return (
    <svg
      className={`ri-close-circle-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.64628 9.17538L9.99984 9.52894L10.3534 9.17538L12.3568 7.1719L12.8282 7.6433L10.8248 9.64677L10.4712 10.0003L10.8248 10.3539L12.8282 12.3573L12.3568 12.8287L10.3534 10.8253L9.99984 10.4717L9.64629 10.8253L7.64281 12.8287L7.17141 12.3573L9.17489 10.3539L9.52845 10.0003L9.17489 9.64677L7.17141 7.6433L7.64281 7.1719L9.64628 9.17538ZM9.99984 17.8337C5.67361 17.8337 2.1665 14.3265 2.1665 10.0003C2.1665 5.67409 5.6736 2.16699 9.99984 2.16699C14.326 2.16699 17.8332 5.67409 17.8332 10.0003C17.8332 14.3265 14.326 17.8337 9.99984 17.8337ZM9.99984 17.167C13.9579 17.167 17.1665 13.9584 17.1665 10.0003C17.1665 6.04228 13.9579 2.83366 9.99984 2.83366C6.04179 2.83366 2.83317 6.04228 2.83317 10.0003C2.83317 13.9584 6.0418 17.167 9.99984 17.167Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
