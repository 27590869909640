/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ClipboardLine = ({ className }) => {
  return (
    <svg
      className={`clipboard-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g">
        <path
          className="path"
          d="M5.83333 3.83366H6.33333V3.33366V2.16699H13.6667V3.33366V3.83366H14.1667H16.6722C16.8531 3.83366 17 3.98054 17 4.16149V17.5058C17 17.6868 16.8531 17.8337 16.6722 17.8337H3.32783C3.14682 17.8337 3 17.6868 3 17.5058V4.16149C3 3.9805 3.14687 3.83366 3.32783 3.83366H5.83333ZM6.33333 5.00033V4.50033H5.83333H4.16667H3.66667V5.00033V16.667V17.167H4.16667H15.8333H16.3333V16.667V5.00033V4.50033H15.8333H14.1667H13.6667V5.00033V6.16699H6.33333V5.00033ZM7.5 2.83366H7V3.33366V5.00033V5.50033H7.5H12.5H13V5.00033V3.33366V2.83366H12.5H7.5Z"
          fill="#FCFCFC"
          stroke="#FCFCFC"
        />
      </g>
    </svg>
  );
};
