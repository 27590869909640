import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
// import Cookies from "js-cookie";
import { Dialog, Alert, AlertTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Routes from "./routes";
import { swagifyLoginData } from "./store/slices/swagifyLoginSlice";

const App = () => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT;

  const dispatch = useDispatch();

  const swagifyLogin = useCallback(
    (requestOptions) => {
      const url = `${apiBaseUrl}swagify-login`;
      axios
        .post(
          url,
          {
            username: "dezignwise@gmail.com",
            password: "test1234",
          },
          requestOptions,
          {
            signal: AbortSignal.timeout(30000),
          }
        )
        .then(
          (response) => {
            localStorage.setItem(
              "access_token",
              response?.data?.data?.access_token
            );
            localStorage.setItem("swagify-login", true);
            dispatch(swagifyLoginData(true));
          },
          (error) => {
            localStorage.setItem("swagify-login", false);
            setTitle("Swagify Login");
            setErrorMessage(error?.response?.data?.message);
          }
        );
    },
    [apiBaseUrl, dispatch]
  );

  useEffect(() => {
    const url = `${apiBaseUrl}customer-login`;
    axios
      .post(
        url,
        {
          email: "dev_pixflo@pixflo.com",
          password: "12345678",
        },
        {
          signal: AbortSignal.timeout(30000),
        }
      )
      .then(
        (response) => {
          /* Cookies.set("user-auth-token", response.data.data.token, {
        domain: "https://dev-crm-app.swagflo.com",
        expires: response.data.data.expires_in
      }); */
          localStorage.setItem("user-auth-token", response.data.data.token);
          localStorage.setItem("user-login", true);
          localStorage.setItem("user-name", response.data.data.user.name);
          localStorage.setItem("apiBaseUrl", apiBaseUrl);
          swagifyLogin({
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + response.data.data.token,
            },
          });
        },
        (error) => {
          localStorage.setItem("user-login", false);
          setOpen(true);
          setTitle("Customer Login");
          setErrorMessage(error?.response?.data?.message);
        }
      );
  }, [apiBaseUrl, swagifyLogin]);

  return (
    <>
      <Routes />
      {open && (
        <Dialog open={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            style={{ backgroundColor: "#fcfcfc" }}
          >
            <AlertTitle>{title}</AlertTitle>
            {errorMessage}
          </Alert>
        </Dialog>
      )}
    </>
  );
};

export default App;
