import React, { useState, useEffect } from "react";
import { Divider6 } from "../../icons/Divider6";
import { ArrowGoBackLineUndo } from "../../icons/ArrowGoBackLineUndo";
import { ArrowGoForwardLineRedo } from "../../icons/ArrowGoForwardLineRedo";
import { Scissors2Line } from "../../icons/Scissors2Line";
import { ClipboardLine } from "../../icons/ClipboardLine";
import { FileCopyLine } from "../../icons/FileCopyLine";
import { ArrowDownSLine } from "../../icons/ArrowDownSLine";
import { ArrowDownSLine3 } from "../../icons/ArrowDownSLine3";
import { GridLine } from "../../icons/GridLine";
import { FileForbidLineClear } from "../../icons/FileForbidLineClear";
import { ShapeLineSelectAll1 } from "../../icons/ShapeLineSelectAll1";
import { Ruler2Line } from "../../icons/Ruler2Line";
import { LockLine1 } from "../../icons/LockLine1";
import { KeyboardLine } from "../../icons/KeyboardLine";
import { RestartLine } from "../../icons/RestartLine";
import "./Topbar.css";

export const Topbar = ({
  cutElement,
  copyElement,
  pasteElement,
  alignElement,
  selectAll,
  element,
  onUpdate,
  onLockUnlock,
  canvas,
  rotateElement,
  toggleGrid,
  addToGroup,
  clearPage,
  undoAction,
  redoAction,
}) => {
  const [flip, setFlip] = useState(false);
  const [align, setAlign] = useState(false);
  const [distribute, setDistribute] = useState(false);
  const [ruler, setRuler] = useState(false);
  const [state, setState] = useState({ ...element });

  useEffect(() => {
    setState({ ...element });
  }, [element]);

  const setFlipX = () => {
    const item = canvas.getActiveObject();
    if (item) {
      if (state?.flipX) {
        let flipx = state?.flipX === true ? false : true;
        setState({ ...state, flipX: flipx });
        onUpdate({ flipX: flipx });
      } else {
        setState({ ...state, flipX: true });
        onUpdate({ flipX: true });
      }
    }
  };

  const setFlipY = () => {
    const item = canvas.getActiveObject();
    if (item) {
      if (state?.flipY) {
        let flipy = state?.flipY === true ? false : true;
        setState({ ...state, flipY: flipy });
        onUpdate({ flipY: flipy });
      } else {
        setState({ ...state, flipY: true });
        onUpdate({ flipY: true });
      }
    }
  };

  const lockUnlock = () => {
    if (state?.lock === true) {
      setState({ ...state, lock: false });
      onLockUnlock({
        lock: false,
        lockMovementX: false,
        lockMovementY: false,
        lockRotation: false,
        lockScalingX: false,
        lockScalingY: false,
        editable: true,
      });
    } else {
      setState({ ...state, lock: true });
      onLockUnlock({
        lock: true,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        editable: false,
      });
    }
  };

  return (
    <>
      <div className="topbar-container">
        <div className="actions-3">
          <div className="action-pack-4">
            <div className="icons-left-4">
              <div className="top-action-wrapper">
                <div
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      undoAction();
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  title="Undo"
                >
                  <ArrowGoBackLineUndo
                    className="arrow-go-back-line-undo-instance"
                    color="#FCFCFC"
                  />
                </div>
                <div
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      redoAction();
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  title="Redo"
                >
                  <ArrowGoForwardLineRedo className="arrow-go-forward-2" />
                </div>
                <div
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      cutElement();
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  title="Cut"
                >
                  <Scissors2Line className="scissors-2-line-instance" />
                </div>
                <div
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      copyElement();
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  title="Copy"
                >
                  <ClipboardLine className="clipboard-line-4" />
                </div>
                <div
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      pasteElement();
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  title="Paste"
                >
                  <FileCopyLine className="file-copy-line-4" />
                </div>
                <Divider6 className="divider-19" />
              </div>
              <div className="element-action-wrapper">
                <div
                  className="item-32"
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      addToGroup();
                      setDistribute(false);
                      setAlign(false);
                      setFlip(false);
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <div className="text-wrapper-71">Group</div>
                </div>
                <div
                  className="item-31"
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      setDistribute(false);
                      setAlign(false);
                      setFlip((prevFlip) => !prevFlip);
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <div className="text-wrapper-71">Flip</div>
                  <ArrowDownSLine className="icon-instance-node-7" />
                </div>
                <div
                  className="item-33"
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      setFlip(false);
                      setDistribute(false);
                      setAlign((prevAlign) => !prevAlign);
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <div className="text-wrapper-71">Align</div>
                  <ArrowDownSLine3 className="icon-instance-node-7" />
                </div>
                <div
                  className="item-34"
                  onClick={() => {
                    if (!state?.lock || state?.lock === false) {
                      setFlip(false);
                      setAlign(false);
                      setDistribute((prevDistribute) => !prevDistribute);
                    }
                  }}
                  style={
                    state?.lock === true
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <div className="text-wrapper-71">Distribute</div>
                  <ArrowDownSLine3 className="icon-instance-node-7" />
                </div>
              </div>
            </div>
            <div className="icons-right-4">
              {ruler === true && (
                <div className="ruler-wrapper">
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      id="bt1"
                      name="unit"
                      // checked={true}
                      /* checked={
                        state?.pathSide === "left" || !state?.pathSide
                          ? true
                          : false
                      } */
                      onChange={(e) => {
                        // setReverseArc(e.target.value);
                      }}
                      value={"px"}
                    />
                    <label htmlFor="bt1">PX</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      id="bt2"
                      name="unit"
                      // checked={state?.pathSide === "right" ? true : false}
                      onChange={(e) => {
                        // setReverseArc(e.target.value);
                      }}
                      value={"in"}
                    />
                    <label htmlFor="bt2">IN</label>
                  </div>
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      id="bt3"
                      name="unit"
                      // checked={state?.pathSide === "right" ? true : false}
                      onChange={(e) => {
                        // setReverseArc(e.target.value);
                      }}
                      value={"cm"}
                    />
                    <label htmlFor="bt3">CM</label>
                  </div>
                </div>
              )}
              <div
                onClick={() => {
                  lockUnlock();
                }}
                title="Lock/Unlock"
              >
                <LockLine1
                  className="lock-line-2 cursor-pointer"
                  color="#FCFCFC"
                />
              </div>
              <div
                onClick={() => {
                  toggleGrid();
                }}
                title="Grid"
              >
                <GridLine className="grid-line-3 cursor-pointer" />
              </div>
              <div
                onClick={() => {
                  setRuler((prev) => !prev);
                }}
                className="cursor-pointer"
                title="Ruler"
              >
                <Ruler2Line
                  className="ruler-2-line-instance cursor-pointer"
                  color="#FCFCFC"
                />
              </div>
              <div
                onClick={() => {
                  selectAll();
                }}
                title="Select All"
              >
                <ShapeLineSelectAll1
                  className="shape-line-select-all-1-instance cursor-pointer"
                  color="#FCFCFC"
                />
              </div>
              <div
                onClick={() => {
                  clearPage();
                }}
                title="Clear Page"
              >
                <FileForbidLineClear
                  className="file-forbid-line-clear-1-instance cursor-pointer"
                  color="#FCFCFC"
                />
              </div>
              <div className="buttons-4">
                <div className="item-36 cursor-pointer">
                  <RestartLine className="icon-instance-node-7" />
                  <div className="text-wrapper-71">Reset Design</div>
                </div>
                <div className="item-35 cursor-pointer">
                  <KeyboardLine className="icon-instance-node-7" />
                  <div className="text-wrapper-71">Shortcuts</div>
                </div>
              </div>
            </div>
          </div>
          {flip === true && (
            <div className="flip-action-wrapper">
              <img
                onClick={() => {
                  rotateElement({ angle: Number(90) });
                }}
                className="action-icon cursor-pointer"
                alt="flip2"
                title="Rotate 90 degree"
                src="/img/flip2.svg"
              />
              <img
                onClick={() => {
                  rotateElement({ angle: Number(180) });
                }}
                className="action-icon cursor-pointer"
                alt="flip1"
                title="Rotate 180 degree"
                src="/img/flip1.svg"
              />
              <img
                onClick={() => {
                  rotateElement({ angle: Number(-90) });
                }}
                className="action-icon cursor-pointer"
                alt="flip3"
                title="Rotate -90 degree"
                src="/img/flip3.svg"
              />
              <img
                onClick={() => {
                  rotateElement({ angle: Number(-180) });
                }}
                className="action-icon cursor-pointer"
                alt="flip4"
                title="Rotate -180 degree"
                src="/img/flip4.svg"
              />
              <img
                onClick={() => {
                  setFlipX();
                }}
                className="action-icon cursor-pointer"
                alt="flip5"
                title="Flip Horizontal"
                src="/img/flip5.svg"
              />
              <img
                onClick={() => {
                  setFlipY();
                }}
                className="action-icon cursor-pointer"
                alt="flip6"
                title="Flip Vertical"
                src="/img/flip6.svg"
              />
            </div>
          )}
          {align === true && (
            <div className="align-action-wrapper">
              <img
                onClick={() => {
                  alignElement("hl");
                }}
                className="action-icon cursor-pointer"
                alt="Horizontal left align"
                title={"Horizontal Left"}
                src="/img/horizontal-left-align.svg"
              />
              <img
                onClick={() => {
                  alignElement("hc");
                }}
                className="action-icon cursor-pointer"
                alt="Horizontal center align"
                title={"Horizontal Center"}
                src="/img/horizontal-center-align.svg"
              />
              <img
                onClick={() => {
                  alignElement("hr");
                }}
                className="action-icon cursor-pointer"
                alt="Horizontal right align"
                title={"Horizontal Right"}
                src="/img/horizontal-right-align.svg"
              />
              <img
                onClick={() => {
                  alignElement("vt");
                }}
                className="action-icon cursor-pointer"
                alt="Vertical top align"
                title={"Top"}
                src="/img/vertical-top-align.svg"
              />
              <img
                onClick={() => {
                  alignElement("vc");
                }}
                className="action-icon cursor-pointer"
                alt="Vertical center align"
                title={"Vertical Middle"}
                src="/img/vertical-center-align.svg"
              />
              <img
                onClick={() => {
                  alignElement("vb");
                }}
                className="action-icon cursor-pointer"
                alt="Vertical bottom align"
                title={"Bottom"}
                src="/img/vertical-bottom-align.svg"
              />
            </div>
          )}
          {distribute === true && (
            <div className="distribute-action-wrapper">
              <img
                className="action-icon cursor-pointer"
                alt="Vertical center align"
                src="/img/vertical-center-align.svg"
              />
              <img
                className="action-icon cursor-pointer"
                alt="Vertical top align"
                src="/img/vertical-top-align.svg"
              />
              <img
                className="action-icon cursor-pointer"
                alt="Horizontal center align"
                src="/img/horizontal-center-align.svg"
              />
              <img
                className="action-icon cursor-pointer"
                alt="Vertical bottom align"
                src="/img/vertical-bottom-align.svg"
              />
              <img
                className="action-icon cursor-pointer"
                alt="Horizontal left align"
                src="/img/horizontal-left-align.svg"
              />
              <img
                className="action-icon cursor-pointer"
                alt="Horizontal right align"
                src="/img/horizontal-right-align.svg"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
