/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ArrowDownSLine6 = ({ color = "#14142B", className }) => {
  return (
    <svg
      className={`arrow-down-s-line-6 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.99959 10.9763L14.1244 6.85156L15.3029 8.03007L9.99959 13.3334L4.69629 8.03007L5.87481 6.85156L9.99959 10.9763Z"
        fill={color}
      />
    </svg>
  );
};

ArrowDownSLine6.propTypes = {
  color: PropTypes.string,
};
