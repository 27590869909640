import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const variantSlice = createSlice({
  name: "variant",
  initialState,
  reducers: {
    variantDetail(state, action) {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { variantDetail } = variantSlice.actions;

export default variantSlice.reducer;
