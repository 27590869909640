import React from "react";
import { Dialog, Alert, AlertTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const AlertComponent = ({ alert, title, errorMessage }) => {
  const [open, setOpen] = React.useState(alert);

  return (
    <>
      <Dialog open={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          style={{ backgroundColor: "#fcfcfc" }}
        >
          <AlertTitle>{title}</AlertTitle>
          {errorMessage}
        </Alert>
      </Dialog>
    </>
  );
};

export default AlertComponent;
