import React from "react";

const Loading = () => {
  return (
    <div style={{ position: "absolute", left: "50%", top: "100px" }}>
      <img
        style={{
          position: "relative",
          left: "0",
          top: "0",
          width: "100px",
          height: "auto",
          transform: "none",
        }}
        src="/img/loader.gif"
        alt="loader"
      />
    </div>
  );
};

export default Loading;
