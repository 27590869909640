import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Avatar = ({
  initials = "BP",
  type,
  status,
  border,
  story,
  person,
  className,
  overlapGroupClassName,
  rectangle = "/img/rectangle-457-1.svg",
}) => {
  return (
    <div className={`avatar story-${story} ${person} status-${status} ${type} border-${border} ${className}`}>
      {(border || status || type === "initials") && (
        <div className={`overlap-group ${overlapGroupClassName}`}>
          {type === "initials" && (border || status) && (!border || !status) && (
            <div className="BP">{status && <>{initials}</>}</div>
          )}

          {(!border || (!status && type === "initials")) && (
            <div className="status-2">{!status && <>{initials}</>}</div>
          )}

          {border && ["photo", "vector"].includes(type) && (
            <img
              className="img"
              alt="Avatar"
              src={
                person === "lisha-cook"
                  ? "/img/avatar.png"
                  : person === "dallas-grimsley"
                  ? "/img/avatar-6.png"
                  : person === "latoyia-hoy"
                  ? "/img/avatar-12.png"
                  : person === "renaldo-holmer"
                  ? "/img/avatar-19.png"
                  : person === "carmela-nieves"
                  ? "/img/avatar-25.png"
                  : person === "raj-raunak"
                  ? "/img/avatar-33.png"
                  : person === "neda-mikus"
                  ? "/img/avatar-40.png"
                  : person === "joaquin-martinek"
                  ? "/img/avatar-46.png"
                  : person === "sheree-monier"
                  ? "/img/avatar-52.png"
                  : person === "alton-caroll"
                  ? "/img/avatar-62.png"
                  : person === "yi-lee"
                  ? "/img/avatar-68.png"
                  : person === "jonathon-benefiel"
                  ? "/img/avatar-29.png"
                  : person === "julieta-mclain"
                  ? "/img/avatar-30.png"
                  : person === "desmond-camire"
                  ? "/img/avatar-80.png"
                  : person === "antonietta-frier"
                  ? "/img/avatar-86.png"
                  : person === "pablo-zulauf"
                  ? "/img/avatar-15.png"
                  : person === "zaida-valez"
                  ? "/img/avatar-98.png"
                  : person === "abdullah-cyrus"
                  ? "/img/avatar-104.png"
                  : person === "pearl-torgrimson"
                  ? "/img/avatar-93.png"
                  : person === "tommy-schlosser"
                  ? "/img/avatar-114.png"
                  : type === "photo" && !status
                  ? "/img/rectangle-457-2.svg"
                  : status && type === "photo"
                  ? "/img/bg-2.svg"
                  : undefined
              }
            />
          )}

          {status && type === "initials" && border && (
            <>
              <div className="BG" />
              <div className="text-wrapper">{initials}</div>
            </>
          )}

          {status && border && <div className="status" />}
        </div>
      )}

      {!border && !status && ["photo", "vector"].includes(type) && (
        <img
          className={`img-2 ${overlapGroupClassName}`}
          alt="Avatar"
          src={
            person === "lisha-cook"
              ? "/img/avatar.png"
              : person === "dallas-grimsley"
              ? "/img/avatar-6.png"
              : person === "latoyia-hoy"
              ? "/img/avatar-12.png"
              : person === "renaldo-holmer"
              ? "/img/avatar-19.png"
              : person === "carmela-nieves"
              ? "/img/avatar-25.png"
              : person === "raj-raunak"
              ? "/img/avatar-33.png"
              : person === "neda-mikus"
              ? "/img/avatar-40.png"
              : person === "joaquin-martinek"
              ? "/img/avatar-46.png"
              : person === "sheree-monier"
              ? "/img/avatar-52.png"
              : person === "alton-caroll"
              ? "/img/avatar-62.png"
              : person === "yi-lee"
              ? "/img/avatar-68.png"
              : person === "jonathon-benefiel"
              ? "/img/avatar-29.png"
              : person === "julieta-mclain"
              ? "/img/avatar-30.png"
              : person === "desmond-camire"
              ? "/img/avatar-80.png"
              : person === "antonietta-frier"
              ? "/img/avatar-86.png"
              : person === "pablo-zulauf"
              ? "/img/avatar-15.png"
              : !story && person === "zaida-valez"
              ? "/img/avatar-98.png"
              : story && person === "zaida-valez"
              ? "/img/avatar-99.png"
              : person === "abdullah-cyrus"
              ? "/img/avatar-104.png"
              : person === "pearl-torgrimson"
              ? "/img/avatar-93.png"
              : person === "tommy-schlosser"
              ? "/img/avatar-114.png"
              : !story && type === "photo"
              ? rectangle
              : story && type === "photo"
              ? "/img/bg-3.svg"
              : undefined
          }
        />
      )}
    </div>
  );
};

Avatar.propTypes = {
  initials: PropTypes.string,
  type: PropTypes.oneOf(["vector", "initials", "photo"]),
  status: PropTypes.bool,
  border: PropTypes.bool,
  story: PropTypes.bool,
  person: PropTypes.oneOf([
    "neda-mikus",
    "pearl-torgrimson",
    "carmela-nieves",
    "pablo-zulauf",
    "yi-lee",
    "antonietta-frier",
    "latoyia-hoy",
    "julieta-mclain",
    "default",
    "raj-raunak",
    "jonathon-benefiel",
    "alton-caroll",
    "zaida-valez",
    "desmond-camire",
    "abdullah-cyrus",
    "joaquin-martinek",
    "lisha-cook",
    "tommy-schlosser",
    "sheree-monier",
    "dallas-grimsley",
    "renaldo-holmer",
  ]),
  rectangle: PropTypes.string,
};
