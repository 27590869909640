/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const RestartLine = ({ className }) => {
  return (
    <svg
      className={`restart-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M9.99984 17.167C11.6949 17.167 13.2532 16.578 14.4801 15.5943L14.8081 16.1848C13.4807 17.2184 11.8127 17.8337 9.99984 17.8337C5.67361 17.8337 2.1665 14.3265 2.1665 10.0003C2.1665 5.67409 5.6736 2.16699 9.99984 2.16699C14.326 2.16699 17.8332 5.67409 17.8332 10.0003C17.8332 11.3805 17.4766 12.6764 16.8505 13.802L15.0163 10.5003H16.6665H17.1665V10.0003C17.1665 6.04228 13.9579 2.83366 9.99984 2.83366C6.04179 2.83366 2.83317 6.04228 2.83317 10.0003C2.83317 13.9584 6.0418 17.167 9.99984 17.167Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
      />
    </svg>
  );
};
