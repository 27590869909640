import React, { useRef, useState, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { AiGenerateBg } from "../../icons/AiGenerateBg";
import { BasketballLineJerseyNumbers } from "../../icons/BasketballLineJerseyNumbers";
import { BookletLine } from "../../icons/BookletLine";
import { EmojiStickerLineElements } from "../../icons/EmojiStickerLineElements";
import { Folder4LineMyFiles } from "../../icons/Folder4LineMyFiles";
import { FolderImageLine } from "../../icons/FolderImageLine";
import { LayoutGridLine1 } from "../../icons/LayoutGridLine1";
import { StackLineLayers1 } from "../../icons/StackLineLayers1";
import { StarSLineClipart1 } from "../../icons/StarSLineClipart1";
import { TShirtLine } from "../../icons/TShirtLine";
import { Text } from "../../icons/Text";
import Loading from "./Loading/Loading.js";
import "./Sidebar.css";

const Product = lazy(() => import("./Product/Product"));
const TextOptions = lazy(() => import("./TextOptions/TextOptions"));
const Templates = lazy(() => import("./Templates/Templates"));
const Elements = lazy(() => import("./Elements/Elements"));
const Images = lazy(() => import("./Images/Images"));
const Cliparts = lazy(() => import("./Cliparts/Cliparts"));
const NameAndNumbers = lazy(() => import("./NameAndNumbers/NameAndNumbers"));
const Background = lazy(() => import("./Background/Background"));
const Layers = lazy(() => import("./Layers/Layers"));
const Notes = lazy(() => import("./Notes/Notes"));
const SavedDesigns = lazy(() => import("./SavedDesigns/SavedDesigns"));

export const Sidebar = ({
  canvas,
  items,
  setElements,
  setActive,
  addText,
  addItem,
  onUpdate,
  onDelete,
  onLockUnlock,
  canvasZoom,
  rotateElement,
  copyPasteElement,
  alignElement,
  textTransform,
  collapse,
  setCollapse,
  setBackground,
  canvases,
  loadDesign,
  setImgLoading,
  design,
  setDesign,
  imageUpload,
  sendToFront,
  sendToBack,
  pageTabRef,
  setAlert,
  setTitle,
  setErrorMessage,
  updateVariant,
  deleteElement,
  ...rest
}) => {
  const [options, setOptions] = useState(false);
  const [category, setCategory] = useState();
  const productRef = useRef(null);
  const textRef = useRef(null);
  const templatesRef = useRef(null);
  const elementsRef = useRef(null);
  const imagesRef = useRef(null);
  const clipartsRef = useRef(null);
  const nameandnumbersRef = useRef(null);
  const backgroundRef = useRef(null);
  const layersRef = useRef(null);
  const notesRef = useRef(null);
  const saveddesignsRef = useRef(null);

  // color state
  const [tab, setTab] = useState("product");
  const product = useSelector((state) => state.product);
  const [state, setState] = useState({
    printing_method: product?.print_options[0] || "DTG",
    colors: [product?.colors[0]],
    mount: false,
    default_size: product?.sizes[0],
  });

  const filteredItem = items.filter((elem, i) => {
    return elem.selected;
  });

  const activeOption = (elem) => {
    setCollapse(false);
    setOptions(false);
    const item = canvas.getActiveObject();
    switch (elem) {
      case "product":
        productRef.current.classList.add("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("product");
        break;

      case "text":
        productRef.current.classList.remove("active");
        textRef.current.classList.add("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("text");
        if (
          item &&
          item?.metaData &&
          item?.metaData?.type === "text" &&
          item?.metaData?.lock === false
        ) {
          setOptions(true);
        }
        break;

      case "templates":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.add("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("templates");
        break;

      case "elements":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.add("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("elements");
        break;

      case "images":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.add("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("images");
        if (
          item &&
          item?.metaData &&
          item?.metaData?.type === "image" &&
          item?.metaData?.lock === false
        ) {
          setOptions(true);
        }
        break;

      case "cliparts":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.add("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("cliparts");
        if (
          item &&
          item?.metaData &&
          item?.metaData?.type === "clipart" &&
          item?.metaData?.lock === false
        ) {
          setOptions(true);
        }
        break;

      case "nameandnumbers":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.add("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("nameandnumbers");
        break;

      case "background":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.add("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("background");
        if (
          item &&
          item?.metaData &&
          item?.metaData?.type === "background" &&
          item?.metaData?.lock === false
        ) {
          setOptions(true);
        }
        break;

      case "layers":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.add("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("layers");
        break;

      case "notes":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.add("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("notes");
        break;

      case "saveddesigns":
        productRef.current.classList.remove("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.add("active");
        setTab("saveddesigns");
        break;

      default:
        productRef.current.classList.add("active");
        textRef.current.classList.remove("active");
        templatesRef.current.classList.remove("active");
        elementsRef.current.classList.remove("active");
        imagesRef.current.classList.remove("active");
        clipartsRef.current.classList.remove("active");
        nameandnumbersRef.current.classList.remove("active");
        backgroundRef.current.classList.remove("active");
        layersRef.current.classList.remove("active");
        notesRef.current.classList.remove("active");
        saveddesignsRef.current.classList.remove("active");
        setTab("product");
        break;
    }
  };

  function handleOptions(value) {
    setOptions(value);
  }

  function handleCategory(value) {
    setCategory(value);
  }

  function handleTab(value) {
    switch (value) {
      case "text":
        activeOption("text");
        break;
      case "image":
        activeOption("images");
        break;
      case "background":
        activeOption("background");
        break;
      case "clipart":
        activeOption("cliparts");
        break;
      default:
        activeOption("product");
        break;
    }
  }

  return (
    <>
      <div
        className="sidebar-container"
        style={collapse === true ? { width: "91px" } : { width: "408px" }}
      >
        <div className="sidebar-background-layer">
          <div className="icons-4 sidebar-icons">
            <div className="tool-item-wrapper-4">
              <div
                onClick={() => {
                  activeOption("product");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer active"
                ref={productRef}
              >
                <div className="product-variant item-variant-29">
                  <TShirtLine
                    className="icon-instance-node-8"
                    color={tab === "product" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="product text-wrapper-75">Product</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("text");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={textRef}
              >
                <div className="text-variant item-variant-29">
                  <Text
                    className="icon-instance-node-8"
                    color={tab === "text" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="text text-wrapper-75">Text</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("templates");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={templatesRef}
              >
                <div className="templates-variant item-variant-30">
                  <LayoutGridLine1
                    className="icon-instance-node-8"
                    color={tab === "templates" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="template text-wrapper-75">Templates</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("elements");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={elementsRef}
              >
                <div className="elements-variant item-variant-31">
                  <EmojiStickerLineElements
                    className="icon-instance-node-8"
                    color={tab === "elements" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="elements-text text-wrapper-75">Elements</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("images");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={imagesRef}
              >
                <div className="images-variant item-variant-32">
                  <FolderImageLine
                    className="icon-instance-node-8"
                    color={tab === "images" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="images-text text-wrapper-75">Images</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("cliparts");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={clipartsRef}
              >
                <div className="cliparts-variant item-variant-33">
                  <StarSLineClipart1
                    className="icon-instance-node-8"
                    color={tab === "cliparts" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="cliparts-text text-wrapper-75">Cliparts</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("nameandnumbers");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={nameandnumbersRef}
              >
                <div className="namenumbers-variant item-variant-34">
                  <BasketballLineJerseyNumbers
                    className="basketball-line-jersey-numbers-instance"
                    color={tab === "nameandnumbers" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="namenumbers-text name-numbers-4">
                    Name &amp;
                    <br />
                    Numbers
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("background");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={backgroundRef}
              >
                <div className="background-variant item-variant-34">
                  <AiGenerateBg
                    className="icon-instance-node-8"
                    color={tab === "background" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="background-text text-wrapper-75">
                    Background
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("layers");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={layersRef}
              >
                <div className="layers-variant item-variant-35">
                  <StackLineLayers1
                    className="icon-instance-node-8"
                    color={tab === "layers" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="layers-text text-wrapper-75">Layers</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("notes");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={notesRef}
              >
                <div className="notes-variant item-variant-36">
                  <BookletLine
                    className="icon-instance-node-8"
                    color={tab === "notes" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="notes-text text-wrapper-75">Notes</div>
                </div>
              </div>
              <div
                onClick={() => {
                  activeOption("saveddesigns");
                  canvas.discardActiveObject();
                  canvas.renderAll();
                }}
                className="design-tool-item-7 cursor-pointer"
                ref={saveddesignsRef}
              >
                <div className="saveddesigns-variant item-variant-36">
                  <Folder4LineMyFiles
                    className="icon-instance-node-8"
                    color={tab === "saveddesigns" ? "#000000" : "#FCFCFC"}
                  />
                  <div className="saveddesigns-text saved-designs-4">
                    Saved <br />
                    Designs
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tab === "product" && (
            <Suspense fallback={<Loading />}>
              <Product
                canvas={canvas}
                setImgLoading={setImgLoading}
                setAlert={setAlert}
                setTitle={setTitle}
                setErrorMessage={setErrorMessage}
                updateVariant={updateVariant}
                state={state}
                setState={setState}
              />
            </Suspense>
          )}
          {tab === "text" && (
            <Suspense fallback={<Loading />}>
              <>
                {filteredItem?.length > 0 ? (
                  <>
                    {filteredItem?.map((ele, i) => (
                      <TextOptions
                        key={i}
                        canvas={canvas}
                        addText={addText}
                        items={items}
                        element={ele}
                        setElements={setElements}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        onLockUnlock={onLockUnlock}
                        canvasZoom={canvasZoom}
                        rotateElement={rotateElement}
                        copyPasteElement={copyPasteElement}
                        alignElement={alignElement}
                        textTransform={textTransform}
                        handleOptions={handleOptions}
                        options={options}
                        handleCategory={handleCategory}
                        category={category}
                        sendToFront={sendToFront}
                        sendToBack={sendToBack}
                        setAlert={setAlert}
                        setTitle={setTitle}
                        setErrorMessage={setErrorMessage}
                      />
                    ))}
                  </>
                ) : (
                  <TextOptions
                    canvas={canvas}
                    addText={addText}
                    items={items}
                    setElements={setElements}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onLockUnlock={onLockUnlock}
                    canvasZoom={canvasZoom}
                    rotateElement={rotateElement}
                    copyPasteElement={copyPasteElement}
                    alignElement={alignElement}
                    textTransform={textTransform}
                    handleOptions={handleOptions}
                    options={options}
                    handleCategory={handleCategory}
                    category={category}
                    sendToFront={sendToFront}
                    sendToBack={sendToBack}
                    setAlert={setAlert}
                    setTitle={setTitle}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </>
            </Suspense>
          )}
          {tab === "templates" && (
            <Suspense fallback={<Loading />}>
              <>
                {filteredItem?.length > 0 ? (
                  <>
                    {filteredItem?.map((ele, i) => (
                      <Templates
                        key={i}
                        canvas={canvas}
                        canvases={canvases}
                        loadDesign={loadDesign}
                        setImgLoading={setImgLoading}
                        options={options}
                        handleOptions={handleOptions}
                        imageUpload={imageUpload}
                        addItem={addItem}
                        element={ele}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        rotateElement={rotateElement}
                        sendToBack={sendToBack}
                        sendToFront={sendToFront}
                        setAlert={setAlert}
                        setTitle={setTitle}
                        setErrorMessage={setErrorMessage}
                      />
                    ))}
                  </>
                ) : (
                  <Templates
                    canvas={canvas}
                    canvases={canvases}
                    loadDesign={loadDesign}
                    setImgLoading={setImgLoading}
                    options={options}
                    handleOptions={handleOptions}
                    imageUpload={imageUpload}
                    addItem={addItem}
                    onUpdate={onUpdate}
                    setAlert={setAlert}
                    setTitle={setTitle}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </>
            </Suspense>
          )}
          {tab === "elements" && (
            <Suspense fallback={<Loading />}>
              <>
                {filteredItem?.length > 0 ? (
                  <>
                    {filteredItem?.map((ele, i) => (
                      <Elements
                        key={i}
                        canvas={canvas}
                        options={options}
                        handleOptions={handleOptions}
                        addItem={addItem}
                        element={ele}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        rotateElement={rotateElement}
                        sendToBack={sendToBack}
                        sendToFront={sendToFront}
                        setAlert={setAlert}
                        setTitle={setTitle}
                        setErrorMessage={setErrorMessage}
                      />
                    ))}
                  </>
                ) : (
                  <Elements
                    canvas={canvas}
                    options={options}
                    handleOptions={handleOptions}
                    addItem={addItem}
                    onUpdate={onUpdate}
                    setAlert={setAlert}
                    setTitle={setTitle}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </>
            </Suspense>
          )}
          {tab === "images" && (
            <Suspense fallback={<Loading />}>
              <>
                {filteredItem?.length > 0 ? (
                  <>
                    {filteredItem?.map((ele, i) => (
                      <Images
                        key={i}
                        canvas={canvas}
                        addItem={addItem}
                        items={items}
                        element={ele}
                        setElements={setElements}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        onLockUnlock={onLockUnlock}
                        canvasZoom={canvasZoom}
                        rotateElement={rotateElement}
                        copyPasteElement={copyPasteElement}
                        alignElement={alignElement}
                        textTransform={textTransform}
                        handleOptions={handleOptions}
                        options={options}
                        sendToFront={sendToFront}
                        sendToBack={sendToBack}
                        imageUpload={imageUpload}
                        setImgLoading={setImgLoading}
                        setAlert={setAlert}
                        setTitle={setTitle}
                        setErrorMessage={setErrorMessage}
                      />
                    ))}
                  </>
                ) : (
                  <Images
                    canvas={canvas}
                    addItem={addItem}
                    items={items}
                    setElements={setElements}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onLockUnlock={onLockUnlock}
                    canvasZoom={canvasZoom}
                    rotateElement={rotateElement}
                    copyPasteElement={copyPasteElement}
                    alignElement={alignElement}
                    textTransform={textTransform}
                    handleOptions={handleOptions}
                    options={options}
                    sendToFront={sendToFront}
                    sendToBack={sendToBack}
                    imageUpload={imageUpload}
                    setImgLoading={setImgLoading}
                    setAlert={setAlert}
                    setTitle={setTitle}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </>
            </Suspense>
          )}
          {tab === "cliparts" && (
            <Suspense fallback={<Loading />}>
              <>
                {filteredItem?.length > 0 ? (
                  <>
                    {filteredItem?.map((ele, i) => (
                      <Cliparts
                        key={i}
                        canvas={canvas}
                        addItem={addItem}
                        items={items}
                        element={ele}
                        setElements={setElements}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        onLockUnlock={onLockUnlock}
                        canvasZoom={canvasZoom}
                        rotateElement={rotateElement}
                        copyPasteElement={copyPasteElement}
                        alignElement={alignElement}
                        textTransform={textTransform}
                        handleOptions={handleOptions}
                        options={options}
                        sendToFront={sendToFront}
                        sendToBack={sendToBack}
                        setAlert={setAlert}
                        setTitle={setTitle}
                        setErrorMessage={setErrorMessage}
                      />
                    ))}
                  </>
                ) : (
                  <Cliparts
                    canvas={canvas}
                    addItem={addItem}
                    items={items}
                    setElements={setElements}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onLockUnlock={onLockUnlock}
                    canvasZoom={canvasZoom}
                    rotateElement={rotateElement}
                    copyPasteElement={copyPasteElement}
                    alignElement={alignElement}
                    textTransform={textTransform}
                    handleOptions={handleOptions}
                    options={options}
                    sendToFront={sendToFront}
                    sendToBack={sendToBack}
                    setAlert={setAlert}
                    setTitle={setTitle}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </>
            </Suspense>
          )}
          {tab === "background" && (
            <Suspense fallback={<Loading />}>
              <>
                {filteredItem?.length > 0 ? (
                  <>
                    {filteredItem?.map((ele, i) => (
                      <Background
                        key={i}
                        canvas={canvas}
                        addItem={addItem}
                        items={items}
                        element={ele}
                        setElements={setElements}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        canvasZoom={canvasZoom}
                        rotateElement={rotateElement}
                        setBackground={setBackground}
                        handleOptions={handleOptions}
                        options={options}
                        sendToFront={sendToFront}
                        sendToBack={sendToBack}
                        setAlert={setAlert}
                        setTitle={setTitle}
                        setErrorMessage={setErrorMessage}
                      />
                    ))}
                  </>
                ) : (
                  <Background
                    canvas={canvas}
                    addItem={addItem}
                    items={items}
                    setElements={setElements}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onLockUnlock={onLockUnlock}
                    canvasZoom={canvasZoom}
                    rotateElement={rotateElement}
                    setBackground={setBackground}
                    handleOptions={handleOptions}
                    options={options}
                    sendToFront={sendToFront}
                    sendToBack={sendToBack}
                    setAlert={setAlert}
                    setTitle={setTitle}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </>
            </Suspense>
          )}
          {tab === "layers" && (
            <Suspense fallback={<Loading />}>
              <>
                {filteredItem?.length > 0 ? (
                  <>
                    {filteredItem?.map((ele, i) => (
                      <Layers
                        key={i}
                        canvas={canvas}
                        items={items}
                        element={ele}
                        setElements={setElements}
                        setActive={setActive}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        canvasZoom={canvasZoom}
                        rotateElement={rotateElement}
                        alignElement={alignElement}
                        handleOptions={handleOptions}
                        options={options}
                        handleTab={handleTab}
                        canvases={canvases}
                        pageTabRef={pageTabRef}
                        deleteElement={deleteElement}
                      />
                    ))}
                  </>
                ) : (
                  <Layers
                    canvas={canvas}
                    items={items}
                    setElements={setElements}
                    setActive={setActive}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    canvasZoom={canvasZoom}
                    rotateElement={rotateElement}
                    alignElement={alignElement}
                    handleOptions={handleOptions}
                    options={options}
                    handleTab={handleTab}
                    canvases={canvases}
                    pageTabRef={pageTabRef}
                    deleteElement={deleteElement}
                  />
                )}
              </>
            </Suspense>
          )}
          {tab === "notes" && (
            <Suspense fallback={<Loading />}>
              <Notes />
            </Suspense>
          )}
          {tab === "saveddesigns" && (
            <Suspense fallback={<Loading />}>
              <SavedDesigns
                canvases={canvases}
                loadDesign={loadDesign}
                setImgLoading={setImgLoading}
                design={design}
                setDesign={setDesign}
                setAlert={setAlert}
                setTitle={setTitle}
                setErrorMessage={setErrorMessage}
              />
            </Suspense>
          )}
          {tab === "nameandnumbers" && (
            <Suspense fallback={<Loading />}>
              <NameAndNumbers />
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
};
