/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ZoomInLine = ({ className }) => {
  return (
    <svg
      className={`zoom-in-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.0257 13.8477L18.5946 17.4166L17.4161 18.5951L13.8472 15.0262C12.5639 16.0528 10.9365 16.667 9.1665 16.667C5.0265 16.667 1.6665 13.307 1.6665 9.16699C1.6665 5.02699 5.0265 1.66699 9.1665 1.66699C13.3065 1.66699 16.6665 5.02699 16.6665 9.16699C16.6665 10.937 16.0523 12.5644 15.0257 13.8477ZM13.3538 13.2293C14.3728 12.1792 14.9998 10.7467 14.9998 9.16699C14.9998 5.94408 12.3894 3.33366 9.1665 3.33366C5.94359 3.33366 3.33317 5.94408 3.33317 9.16699C3.33317 12.3899 5.94359 15.0003 9.1665 15.0003C10.7462 15.0003 12.1787 14.3732 13.2288 13.3542L13.3538 13.2293ZM8.33317 8.33366V5.83366H9.99984V8.33366H12.4998V10.0003H9.99984V12.5003H8.33317V10.0003H5.83317V8.33366H8.33317Z"
        fill="#14142B"
      />
    </svg>
  );
};
