/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const GridLine = ({ className }) => {
  return (
    <svg
      className={`grid-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 19 20"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.0833 8.33333H7.91667V11.6667H11.0833V8.33333ZM12.6667 8.33333V11.6667H15.0417V8.33333H12.6667ZM11.0833 15.8333V13.3333H7.91667V15.8333H11.0833ZM12.6667 15.8333H15.0417V13.3333H12.6667V15.8333ZM11.0833 4.16667H7.91667V6.66667H11.0833V4.16667ZM12.6667 4.16667V6.66667H15.0417V4.16667H12.6667ZM6.33333 8.33333H3.95833V11.6667H6.33333V8.33333ZM6.33333 15.8333V13.3333H3.95833V15.8333H6.33333ZM6.33333 4.16667H3.95833V6.66667H6.33333V4.16667ZM3.16667 2.5H15.8333C16.2706 2.5 16.625 2.8731 16.625 3.33333V16.6667C16.625 17.1269 16.2706 17.5 15.8333 17.5H3.16667C2.72945 17.5 2.375 17.1269 2.375 16.6667V3.33333C2.375 2.8731 2.72945 2.5 3.16667 2.5Z"
        fill="#FCFCFC"
      />
    </svg>
  );
};
