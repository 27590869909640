import {createSlice} from "@reduxjs/toolkit"

const initialState = []

const templatesSlice = createSlice({
    name: "templates",
    initialState,
    reducers: {
        templatesList(state, action){
            if(Array.isArray(action.payload)){
                state.push(...action.payload)
                // return [...state, ...action.payload]
            } else {
                console.error('Payload is not an array: ', action.payload)
            }
        }
    }
})

export const {templatesList} = templatesSlice.actions
export default templatesSlice.reducer