/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ShoppingCartLine = ({ className }) => {
  return (
    <svg
      className={`shopping-cart-line ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.33757 13.3337V3.33366H1.6709V1.66699H4.1709C4.63114 1.66699 5.00423 2.04009 5.00423 2.50033V12.5003H15.3696L17.0362 5.83366H6.6709V4.16699H18.1036C18.5638 4.16699 18.9369 4.54009 18.9369 5.00033C18.9369 5.06846 18.9286 5.13634 18.912 5.20244L16.8287 13.5357C16.736 13.9067 16.4027 14.167 16.0202 14.167H4.1709C3.71066 14.167 3.33757 13.7939 3.33757 13.3337ZM5.00423 19.167C4.08376 19.167 3.33757 18.4208 3.33757 17.5003C3.33757 16.5798 4.08376 15.8337 5.00423 15.8337C5.92471 15.8337 6.6709 16.5798 6.6709 17.5003C6.6709 18.4208 5.92471 19.167 5.00423 19.167ZM15.0042 19.167C14.0837 19.167 13.3376 18.4208 13.3376 17.5003C13.3376 16.5798 14.0837 15.8337 15.0042 15.8337C15.9247 15.8337 16.6709 16.5798 16.6709 17.5003C16.6709 18.4208 15.9247 19.167 15.0042 19.167Z"
        fill="#FCFCFC"
      />
    </svg>
  );
};
