import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const defaultTextStylesSlice = createSlice({
  name: "defaultTextStyles",
  initialState,
  reducers: {
    textStylesList(state, action) {
        return state = [...action.payload];
    }
  },
});

// Action creators are generated for each case reducer function
export const { textStylesList } = defaultTextStylesSlice.actions;

export default defaultTextStylesSlice.reducer;
