/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const LockLine1 = ({ color = "#14142B", className }) => {
  return (
    <svg
      className={`lock-line-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 19 20"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.0417 8.33366H15.8333C16.2706 8.33366 16.625 8.70674 16.625 9.16699V17.5003C16.625 17.9606 16.2706 18.3337 15.8333 18.3337H3.16667C2.72945 18.3337 2.375 17.9606 2.375 17.5003V9.16699C2.375 8.70674 2.72945 8.33366 3.16667 8.33366H3.95833V7.50033C3.95833 4.27867 6.43942 1.66699 9.5 1.66699C12.5606 1.66699 15.0417 4.27867 15.0417 7.50033V8.33366ZM3.95833 10.0003V16.667H15.0417V10.0003H3.95833ZM8.70833 11.667H10.2917V15.0003H8.70833V11.667ZM13.4583 8.33366V7.50033C13.4583 5.19914 11.6861 3.33366 9.5 3.33366C7.31388 3.33366 5.54167 5.19914 5.54167 7.50033V8.33366H13.4583Z"
        fill={color}
      />
    </svg>
  );
};

LockLine1.propTypes = {
  color: PropTypes.string,
};
