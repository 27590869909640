import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const ErrorPage = () => {
  return (
    <Box>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#14142b",
        }}
      >
        <Typography variant="h1" style={{ color: "#fcfcfc" }}>
          404
        </Typography>
        <Typography variant="h3" style={{ color: "#fcfcfc" }}>
          The page you're looking for doesn't exist.
        </Typography>
      </Stack>
    </Box>
  );
};

export default ErrorPage;
